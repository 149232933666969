import React from "react";
import { useResource } from "hooks/useResource";

import { TestimonialService } from "services/user/testimonial.service";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TestimonialDto } from "dto/user/testimonial.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box } from "@mui/material";

import { TestimonialCard } from "components/user/testimonial/TestimonialCard";
import { Carousel } from "components/elements/Carousel/Carousel";

import { CommonTools } from "tools/utils/commontools";
import { SkeletonScnReviews } from "../../elements/Skeleton/SkeletonSection";
import { useList } from "hooks/useList";

const service = new TestimonialService();

const TestimonialList: React.FC<PageComponentProps> = ({
  currentRoute
}) => {
  // const { saveCache, getCache } = useResource();
  const { CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<TestimonialDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 20;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await testimonialService.getList(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getList(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<TestimonialDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      
      ],
      1,
      parseInt(CC("testimonial_home_block", "20")),
      []
    ),
    [],
    true,
    "testimonial_home_block"
  );

  const processTestimonialItem = (obj: TestimonialDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <TestimonialCard obj={obj} />
      </Box>
    );
  };

  const processTestimonialList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowTestimonialCarousel", "3")),
      objects.length
    );

    return (
      <Box>
        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: TestimonialDto, i: number) => {
            return processTestimonialItem(item, i);
          })}
        </Carousel>
      </Box>
    );
  };

  return loading ? <SkeletonScnReviews /> : processTestimonialList();
};

export { TestimonialList };
