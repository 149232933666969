// import React, { useEffect, useState } from "react";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { logger } from "tools/utils/logger";
// import { useResource } from "hooks/useResource";
// import { TypeStudiesService } from "services/nomenclature/typestudies.service";
// import { MySelect } from "components/elements/Select/MySelect";
// import RequestListDTO from "dto/app/requestlist.dto";
// import SelectOptions from "dto/app/selectoptions.dto";
// import ResultListDTO from "dto/app/resultlist.dto";
// import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
// import { CommonTools } from "tools/utils/commontools";
// import { Skeleton } from "@mui/material";
// const typeStudyService = new TypeStudiesService();

import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
import { Status } from "tools/types/status";
import { ServerSelect } from "./newSelect/ServerSelect";
import { TypeStudiesService } from "services/nomenclature/typestudies.service";

// type StudyTypeSelectProps = {
//   setObj: (field: any, value: any) => void;
//   field: any;
//   obj: any;
//   _defaultValue?: any;
//   [key: string]: any; // for the rest of the props which we do not have types for
// };

// const StudyTypeSelect: React.FC<StudyTypeSelectProps> = ({
//   setObj,
//   field,
//   obj,
//   _defaultValue,
//   ...props
// }) => {
//   const { LL } = useResource();
//   const defaultValue = _defaultValue != undefined ? _defaultValue : "";
//   const label = "TypeStudy";
//   const [options, setOptions] = useState<Array<SelectOptions>>(
//     new Array<SelectOptions>()
//   );
//   const [loading, setLoading] = useState<boolean>(true);
//   const [value, setValue] = useState<string>(defaultValue);
//   const [data, setData] = useState<Array<PopulateTypeDto> | undefined>();
//   const onPage = 2000;

//   const [reqList, setReqList] = useState<RequestListDTO>(
//     typeStudyService.getDefaultRequestListSelect(onPage)
//   );

//   const loadObjects = (data: ResultListDTO) => {
//     setData(data.objects);
//   };

//   const getReqList = () => {
//     const reqList = typeStudyService.getDefaultRequestListSelect(onPage);
//     setReqList(reqList);
//   };

//   const getList = () => {
//     setLoading(true);
//     typeStudyService.getList(loadObjects, {}, reqList);
//   };

//   const processOptions = () => {
//     if (data !== undefined) {
//       const options: Array<SelectOptions> = data.map((item) => {
//         const obj = {
//           label: item.name,
//           value: item.id,
//         };
//         return obj;
//       });
//       setOptions(options);
//     }
//   };

//   // useEffect(() => {
//   //     getReqList();
//   // }, []);

//   useEffect(() => {
//     setLoading(true);
//     if (CommonTools.atLeastOneFieldDefined(reqList)) {
//       getList();
//     }
//   }, [reqList]);

//   useEffect(() => {
//     if (data !== undefined) processOptions();
//     checkIsLoading();
//   }, [options, data]);

//   useEffect(() => {
//     if (loading) return;
//     setObj(field, value);
//   }, [value]);

//   const checkIsLoading = () => {
//     let load: boolean = false;
//     if (data === undefined) load = true;
//     if (options === undefined) load = true;
//     // if (value === undefined) load = true;
//     setLoading(load);
//   };

//   return !loading ? (
//     <MySelect
//       options={options}
//       _label={label}
//       setValue={setValue}
//       value={value}
//       {...props}
//     />
//   ) : (
//     <Skeleton variant="rectangular" height={50} />
//   );
// };

// export { StudyTypeSelect };


type Props = {
  field?: string;
  value?: number | string;
  setObjectField: (field: string, value: any) => void;
  label?: string;
};
const service = new TypeStudiesService();
const StudyTypeSelect: React.FC<Props> = ({
  field = "idtypestudies",
  setObjectField,
  value,
  label = "TypeStudy",
}) => {
  const getList = (cb?: any, cbParams?: any, data?: RequestListDTO) => {
    if (!data) data = new RequestListDTO();
    data.filters = [
      RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
    ];
    service.getList(cb, cbParams, data);
  };

  return (
    <ServerSelect
      processOptions={PopulateTypeDto.parseToSelectOptions}
      getList={getList}
      field={field}
      setObjectField={setObjectField}
      value={value}
      label={label}
    />
  );
};

export { StudyTypeSelect };