import React, { useState, useEffect } from "react";

import { useResource } from "hooks/useResource";
import Box from "@mui/material/Box";
import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";

import MyTextField from "components/form/mytextfield";

import { MyButton } from "components/elements/button/MyButton";

import { TeacherService } from "services/user/teacher.service";

import { TeacherDto, TeacherMediaDto } from "dto/user/teacher.dto";
import MyFileField from "components/form/myfilefield";
import { useAuth } from "hooks/useAuth";
import { Skeleton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const service = new TeacherService();

interface Props {
  currentRoute: any;
  objTeacher: TeacherDto;
  loading: boolean;
  setLoading: any;
  setObjectWasChanged: any;
}

const AddTeacherMediaComponent: React.FC<Props> = ({
  currentRoute,
  objTeacher,
  loading,
  setLoading,
  setObjectWasChanged,
  ...props
}) => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  const id = CommonTools.processObjectField(teacher, ["id"]);

  const [obj, setObj] = useState<TeacherMediaDto>(new TeacherMediaDto());
  const [files, setFiles] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const validateAll = () => {
    if (!obj) return;

    setLoading(true);
    obj.idteacher = id ?? id;
    obj.videolocationlessonexample =
      obj.videolocationlessonexample !== undefined
        ? obj.videolocationlessonexample
        : "";
    obj.videolocationpresentation =
      obj.videolocationpresentation !== undefined
        ? obj.videolocationpresentation
        : "";
    obj.deleteattachmentcv =
      obj.deleteattachmentcv !== undefined ? obj.deleteattachmentcv : false;
    obj.deletevideopresentation =
      obj.deletevideopresentation !== undefined
        ? obj.deletevideopresentation
        : false;
    obj.deletevideolessonexample =
      obj.deletevideolessonexample !== undefined
        ? obj.deletevideolessonexample
        : false;
    setObj(obj);
    setLoading(false);
  };

  useEffect(() => {
    validateAll();
  }, [obj]);

  const setCheckDeleteCv = (value: any) => {
    CommonTools.handleCheckValue(obj, setObj, "deleteattachmentcv", value);
  };

  const setCheckDeleteVideoPresentation = (value: any) => {
    CommonTools.handleCheckValue(obj, setObj, "deletevideopresentation", value);
  };

  const setCheckDeleteVideoLessonExample = (value: any) => {
    CommonTools.handleCheckValue(
      obj,
      setObj,
      "deletevideolessonexample",
      value
    );
  };

  const processCVDelete = () => {
    if (!objTeacher) return <></>;
    if (loading) return <></>;
    // logger('objTeacher', objTeacher);
    return (
      <Box display="flex" alignItems="center">
        <DeleteIcon sx={{ color: "red", mr: 1 }} />
        {ComponentCommonTools.processCheckboxDelete(
          objTeacher,
          obj,
          "attachmentcv",
          "deleteattachmentcv",
          "name",
          setCheckDeleteCv,
          LL
        )}
      </Box>
    );
  };

  const processVideoPresentationDelete = () => {
    if (!objTeacher) return <></>;
    if (loading) return <></>;
    return (
      <Box display="flex" alignItems="center">
        <DeleteIcon sx={{ color: "red", mr: 1 }} />
        {ComponentCommonTools.processCheckboxDelete(
          objTeacher,
          obj,
          "videopresentation",
          "deletevideopresentation",
          "videolocation",
          setCheckDeleteVideoPresentation,
          LL
        )}
      </Box>
    );
  };

  const processVideoLessonExampleDelete = () => {
    if (!objTeacher) return <></>;
    if (loading) return <></>;
    return (
      <Box display="flex" alignItems="center">
        <DeleteIcon sx={{ color: "red", mr: 1 }} />
        {ComponentCommonTools.processCheckboxDelete(
          objTeacher,
          obj,
          "videolessonexample",
          "deletevideolessonexample",
          "videolocation",
          setCheckDeleteVideoLessonExample,
          LL
        )}
      </Box>
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (obj !== undefined) {
      obj.idteacher = id;
      if (obj) obj.files = files;
      if (
        objTeacher &&
        (objTeacher.hasOwnProperty("attachmentcv") ||
          objTeacher.hasOwnProperty("videopresentation") ||
          objTeacher.hasOwnProperty("videolessonexample")) &&
        (objTeacher.attachmentcv ||
          objTeacher.videopresentation ||
          objTeacher.videolessonexample)
      ) {
        setLoading(true);
        service.updateMedia(
          CommonTools.handleSuccessAddAndEditStayOnPage,
          { setObjectWasChanged },
          obj
        );
      } else {
        setLoading(true);
        service.addMedia(
          CommonTools.handleSuccessAddAndEditStayOnPage,
          { setObjectWasChanged },
          obj
        );
      }
    }
  };
  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);
  };
  const setObjFile = (field: any, event: any) => {
    setFiles(event.target.files[0]);
  };

  return !loading ? (
    <form onSubmit={handleSubmit}>
      <Box
        p={3}
        maxWidth="md"
        mx={"auto"}
        justifyContent="center"
        display="flex"
        flexDirection="column"
      >
        <Box className="likeCard" mt={1} mb={3}>
          <MyTextField
            tabIndex={0}
            fullWidth
            mb={2}
            id="videolocationlessonexample"
            name="videolocationlessonexample"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("videolocationlessonexample")}
            size="small"
            value={obj.videolocationlessonexample}
            setObj={setObjField}
            _vresults={undefined}
          />

          {processVideoLessonExampleDelete()}
        </Box>
        <Box className="likeCard" mt={1} mb={3}>
          <MyTextField
            tabIndex={1}
            fullWidth
            mb={2}
            id="videolocationpresentation"
            name="videolocationpresentation"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("videolocationpresentation")}
            size="small"
            value={obj.videolocationpresentation}
            setObj={setObjField}
            _vresults={undefined}
          />

          {processVideoPresentationDelete()}
        </Box>
        <Box className="likeCard" mt={1} mb={3}>
          <MyFileField
            tabIndex={2}
            fullWidth
            mb={2}
            id="files"
            name="files"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("files")}
            size="small"
            setObj={setObjFile}
            _vresults={undefined}
          />
          {processCVDelete()}
        </Box>
        <Box textAlign="center" mt={2}>
          <MyButton
            tabIndex={5}
            disabled={isFormDisabled}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_Submit")}
          />
        </Box>
      </Box>
    </form>
  ) : (
    <Skeleton />
  );
};

export default AddTeacherMediaComponent;
