import { Alert, Box, Link, Stack, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyTextField from "components/form/mytextfield";
import { PostTeacherFAQFormDto } from "dto/user/teacherfaq.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useEffect } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObj: PostTeacherFAQFormDto;
  onSubmit: (obj: PostTeacherFAQFormDto) => void;
  loading: boolean;
};

const TeacherFAQForm: React.FC<Props> = ({ defaultObj, onSubmit, loading }) => {
  const { LL, CC } = useResource();
  const [obj, disabled, setObjField, setObject] =
    useForm<PostTeacherFAQFormDto>(
      defaultObj,
      RequiredValidator.getValidators([
        "iduser",
        "message",
        "idcourse",
        "idteacher",
      ])
    );

  useEffect(() => {
    if (!defaultObj) return;
    setObject(defaultObj);
  }, [defaultObj]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <MyTextField
          multiline
          minRows={4}
          fullWidth
          id="message"
          name="message"
          variant="standard"
          color="primary"
          className="form-input"
          _label={LL("Place_you_question ...")}
          size="small"
          value={obj.message}
          setObj={setObjField}
          _vresults={undefined}
        />
        <Stack
          direction="row"
          spacing={2}
          mt={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Alert severity="info">
            <Stack direction="row" spacing={1}>
              <Typography>{LL("Nu uita sa faci cunostinta cu")}</Typography>
              <Link
                href={CC("term_page", "/termeni")}
                target="_blank"
                rel="noopener"
              >
                <Typography>{LL("Regulile de comunicare")}</Typography>
              </Link>
              <Typography>{LL("pe LiveClass")}</Typography>
            </Stack>
          </Alert>
          <MyButton
            tabIndex={4}
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="contained"
            size="small"
            type="submit"
            sx={{ mt: 4 }}
          >
            {LL("Bt_Send_Question")}
          </MyButton>
        </Stack>
      </form>
    </Box>
  );
};

export { TeacherFAQForm };
