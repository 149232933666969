import ResultObjectDTO from "dto/app/resultobject.dto";
import { TeacherRequestProcessedProDto } from "dto/user/teacherrequest.dto";
import { useResource } from "hooks/useResource";
import React, { useState, useEffect } from "react";

import { TeacherRequestService } from "services/user/teacherrequest.service";
import { useAuth } from "hooks/useAuth";

import { MyButton } from "components/elements/button/MyButton";
import { Status } from "tools//types/status";

import { TeacherRequestButtonContent } from "./TeacherRequestButtonContent";

type TeacherRequestButtonProps = {
  [x: string]: any;
  objectWasChanged: string;
};

const service = new TeacherRequestService();
const TeacherRequestButton: React.FC<TeacherRequestButtonProps> = ({
  objectWasChanged,
  ...props
}) => {
  const { teacher } = useAuth();
  const [obj, setObj] = useState<TeacherRequestProcessedProDto | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);

  const getObject = () => {
    if (!teacher) return;
    if (!teacher.hasOwnProperty("id")) return;
    if (!teacher.id) return;
    if (!teacher.hasOwnProperty("status")) return;
    if (!teacher.status) return;
    if (teacher.status === Status.TEACHER_PRO) return;

    setLoading(true);
    service.verifyProcessed(
      teacher.id,
      teacher.status,
      loadObject,
      {}
    );
  };
  const loadObject = (obj: ResultObjectDTO) => {
    if (!obj) return;
    if (!obj.err) {
      const object: TeacherRequestProcessedProDto = obj.obj ? obj.obj : {};
      setObj(object);
      if (object.processed) setDisabled(false);
      else setDisabled(true);
    }
  };
  useEffect(() => {
    getObject();
  }, [teacher, objectWasChanged]);

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  const processButtonContent = () => {
    if (!teacher) return null;
    return <TeacherRequestButtonContent obj={teacher} />;
  };

  const processComponent = () => {
    if (!teacher) return null;
    if (!teacher.hasOwnProperty("id")) return null;
    if (!teacher.id) return null;
    if (!teacher.hasOwnProperty("status")) return null;
    if (!teacher.status) return null;
    if (teacher.status === Status.TEACHER_PRO) return null;

    return (
      <MyButton {...props} disabled={disabled}>
        {processButtonContent()}
      </MyButton>
    );
  };
  return processComponent();
};

export { TeacherRequestButton };
