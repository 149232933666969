import React from "react";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { CourseDto } from "dto/course/course.dto";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";

import { Box, Grid, Stack } from "@mui/material";

import { CourseCharacteristics } from "components/course/course/CourseCharacteristics";
import { TeacherProcessNameCourse } from "components/user/teacher/TeacherProcessNameCourse";
import { MyAvatar } from "components/elements/MyAvatar/MyAvatar";
import BreadcrumbChildrenDto from "dto/app/breadcrumbchildren.dto";

import { MyBreadcrumbsCourse } from "components/elements/MyBreadcrumbs/MyBreadcrumbsCourse";
import { HtmlTag } from "components/form/HtmlTag";
import { ShareDialog } from "components/elements/MyDialog/ShareDialog";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";

import { WishlistIconChecker } from "components/user/wishlist/WishlistIconChecker";

type Props = {
  obj: CourseDto;
  loading: boolean;
  setOpenEnrollmentDialog: any;
  openEnrollmentDialog: boolean;
};

const CoursePageTopBlock: React.FC<Props> = ({
  obj,
  loading,
  setOpenEnrollmentDialog,
  openEnrollmentDialog,
}) => {
  const { LL } = useResource();

  const processCourseTeacher = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacher")) return null;
    if (!obj.teacher) return null;

    return (
      <Box className="created">
        <Box mb={2}>{LL("Creat de:")}</Box>
        {processTeacher()}
      </Box>
    );
  };

  const processWishlistButton = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("id")) return null;
    if (!obj.id) return null;

    return <WishlistIconChecker idCourse={obj.id} />;
  };

  const processTeacher = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("teacher")) return null;
    if (!obj.teacher) return null;

    return (
      <Stack mb={5} direction="row" alignItems="center" spacing={2}>
        {processAvatar()}
        <TeacherProcessNameCourse obj={obj.teacher} />
      </Stack>
    );
  };

  const processAvatar = () => {
    if (!obj) return null;
    let name = "";
    let surname = "";
    if (!obj.hasOwnProperty("teacher")) return null;
    if (!obj.teacher) return null;
    if (!obj.teacher.hasOwnProperty("user")) return null;
    if (!obj.teacher.user) return null;
    if (!obj.teacher.user.hasOwnProperty("usersettings")) return null;
    if (!obj.teacher.user.usersettings) return null;
    if (!obj.teacher.user.usersettings[0]) return null;
    if (obj.teacher.hasOwnProperty("name") && obj.teacher.name)
      name = obj.teacher.name;
    if (obj.teacher.hasOwnProperty("surname") && obj.teacher.surname)
      surname = obj.teacher.surname;
    const localObj = obj.teacher.user.usersettings[0];

    return (
      <MyAvatar
        obj={localObj}
        name={name}
        surname={surname}
        className="avatar"
        sx={{ mr: 1 }}
      />
    );
  };
  const processCourseCharacteristics = () => {
    if (!obj) return null;
    return (
      <CourseCharacteristics
        obj={obj}
        loading={loading}
        setOpenEnrollmentDialog={setOpenEnrollmentDialog}
        openEnrollmentDialog={openEnrollmentDialog}
      />
    );
  };

  const processBreadcrumbChildren = (): BreadcrumbChildrenDto[] => {
    const createHomeBreadcrumb = (): BreadcrumbChildrenDto => {
      const breadcrumb = new BreadcrumbChildrenDto();
      breadcrumb.url = "/";
      breadcrumb.label = LL("crumb_homepage");
      return breadcrumb;
    };

    if (
      !obj ||
      !obj.hasOwnProperty("linkcoursecategory") ||
      !obj.linkcoursecategory ||
      !Array.isArray(obj.linkcoursecategory)
    ) {
      return [createHomeBreadcrumb()];
    }

    let validItems = obj.linkcoursecategory.filter(
      (item) =>
        item &&
        item.hasOwnProperty("coursecategory") &&
        item.coursecategory &&
        item.coursecategory.hasOwnProperty("name") &&
        item.coursecategory.name &&
        item.coursecategory.hasOwnProperty("fullurl") &&
        item.coursecategory.fullurl
    );

    if (validItems.length === 0) {
      return [createHomeBreadcrumb()];
    }

    let arrayOfLinkCourseCategory = validItems.map((item) => {
      const breadcrumbChildrenItem = new BreadcrumbChildrenDto();
      breadcrumbChildrenItem.url = item.coursecategory.fullurl;
      breadcrumbChildrenItem.label = item.coursecategory.name;
      return breadcrumbChildrenItem;
    });

    arrayOfLinkCourseCategory.unshift(createHomeBreadcrumb());

    return arrayOfLinkCourseCategory;
  };

  const processBreadcrumb = () => {
    if (!obj) return null;
    const breadcrumbChildren = processBreadcrumbChildren();
    return (
      <Box className="breadcrumbs">
        <MyBreadcrumbsCourse childs={breadcrumbChildren} />
      </Box>
    );
  };

  const processShortDescription = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("shortdescription")) return null;
    if (!obj.shortdescription) return null;
    return (
      <Box>
        <HtmlTag content={obj.shortdescription} />
      </Box>
    );
  };

  const processShareButton = () => {
    if (!obj) return null;
    if (!obj.hasOwnProperty("fullurl")) return null;
    if (!obj.fullurl) return null;

    let imageUri = "";

    if (obj.hasOwnProperty("gallery") && obj.gallery) {
      const gallery = CommonTools.getGalleryObjectLogo(obj.gallery);
      if (gallery) {
        const urlObject = CommonTools.getFileUrlsGallery(gallery);
        if (urlObject && urlObject.hasOwnProperty("aUrl") && urlObject.aUrl) {
          imageUri = urlObject.aUrl;
        }
      }
    }
    return (
      <ShareDialog
        url={obj.fullurl}
        imageUrl={imageUri}
        size="large"
        buttonContent={
          <Box className="shareBtn">
            <IosShareOutlinedIcon sx={{ mr: 1 }} /> {LL("Share")}
          </Box>
        }
      />
    );
  };

  const processCourseTopBlock = () => {
    if (!obj) return null;

    return (
      <Box>
        <Box className="section1">
          <Box className="container" sx={{ position: "relative" }}>
            <Grid container spacing={2}>
              <Grid item lg={9} sm={12}>
                {processBreadcrumb()}
                <Typography className="title" my={3}>
                  {ComponentCommonTools.processObjectField(obj, "name")}
                </Typography>

                <Typography className="shortDescription" my={3}>
                  {processShortDescription()}
                </Typography>

                {processCourseTeacher()}

                <Stack direction="row" spacing={2} alignItems="center" mb={5}>
                  {processWishlistButton()}
                  {processShareButton()}
                </Stack>
              </Grid>

              <Grid item lg={3} sm={12} className="rightPart">
                {processCourseCharacteristics()}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  };

  return loading ? <Skeleton variant="rectangular" /> : processCourseTopBlock();
};

export { CoursePageTopBlock };
