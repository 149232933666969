import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import { TeacherService } from "services/user/teacher.service";
import { TeacherDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";

import { MyButton } from "components/elements/button/MyButton";
import ValidateTools from "tools/utils/validate.tools";

import { CommonTools } from "tools/utils/commontools";
import { CircularLoading } from "components/elements/loading/CircularLoading";

import MyCheckboxField from "components/form/mycheckboxfield";

const teacherService = new TeacherService();

const EditTeacherPrivacy: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<TeacherDto | undefined>();
  const _id = user !== undefined ? user.id : "";
  const [id, setId] = useState<string>(_id);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [validators, setValidators] = useState<any>();
  const [vresults, setVResults] = useState<any>(false);

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);

    setVResults(v);
    setIsFormDisabled(ValidateTools.vForm(v, field));
    return v;
  };

  const loadObject = (obj: any) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : [];
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined && id !== "")
      teacherService.getByUserId(id, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    getObject();
  }, [currentRoute]);

  useEffect(() => {
    validateAll();
    checkIsLoading();
  }, [obj]);

  useEffect(() => {
    if (user !== undefined) {
      setId(user.id);
    }
  }, [user]);

  const validateAll = () => {
    if (!obj) return;
    // if (!validators) return;
    // if (vresults) return;

    obj.showcourses = obj.showcourses !== undefined ? obj.showcourses : false;
    obj.showprofile = obj.showprofile !== undefined ? obj.showprofile : false;

    setObj(obj);
  };

  const setCheckShowCourses = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t["showcourses"] = value;
    setObj(t);
  };
  const setCheckShowProfile = (value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t["showprofile"] = value;
    setObj(t);
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;
    if (id !== undefined && obj) {
      obj.iduser = id;
      setLoading(true);
      teacherService.addPrivacy(
        CommonTools.handleSuccessAddAndEditStayOnPage,
        { setLoading },
        obj
      );
    }
  };

  return user !== undefined && !loading && obj ? (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          p={3}
          maxWidth="md"
          mx={"auto"}
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ mt: 3 }} className="likeCard">
            <MyCheckboxField
              tabIndex={6}
              id="showcourses"
              name="showcourses"
              _label={LL("showcourses")}
              setObj={setCheckShowCourses}
              checked={obj.showcourses}
              color="primary"
              size="small"
              _vresults={undefined}
            />
          </Box>

          <Box sx={{ mt: 3 }} className="likeCard">
            <MyCheckboxField
              tabIndex={6}
              id="showprofile"
              name="showprofile"
              _label={LL("showprofile")}
              setObj={setCheckShowProfile}
              checked={obj.showprofile}
              color="primary"
              size="small"
              _vresults={undefined}
            />
          </Box>
          <Box textAlign="center" mt={4}>
            <MyButton
              tabIndex={4}
              disabled={isFormDisabled}
              color="primary"
              className="btn"
              variant="contained"
              size="large"
              type="submit"
              children={LL("Bt_Submit")}
            />
          </Box>
        </Box>
      </form>
    </>
  ) : (
    <CircularLoading />
  );
};

export { EditTeacherPrivacy };
