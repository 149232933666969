import { Box, Grid, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { StudyTypeSelect } from "components/elements/Select/StudyTypeSelect";
import MyTextField from "components/form/mytextfield";
import { StudentDto } from "dto/user/student.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: StudentDto;
  onSubmit: (obj: StudentDto) => void;
  loading: boolean;
};

const FormEditStudent: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const [obj, disabled, setObjField] = useForm<StudentDto>(
    defaultObject,
    RequiredValidator.getValidators(["iduser"])
  );
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12}>
            <Typography variant="h6">{LL("Institution")}</Typography>
            <MyTextField
              tabIndex={0}
              fullWidth
              id="institution"
              name="institution"
              className="form-input"
              variant="standard"
              _label={LL("institution")}
              size="small"
              value={obj.institution}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <Typography variant="h6">{LL("Type studies")}</Typography>
            <Box className="labelRemove">
              <StudyTypeSelect
                value={obj.idtypestudies}
                field="idtypestudies"
                setObjectField={setObjField}
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h6">{LL("Biografie")}</Typography>
            <MyCKEditor
              data={obj.bio ? obj.bio : ""}
              setObj={setObjField}
              name="bio"
              field="bio"
            />
          </Grid>
        </Grid>

        <Box textAlign="center" mt={4}>
          <MyButton
            disabled={disabled || loading}
            color="primary"
            className="btn"
            variant="contained"
            size="large"
            type="submit"
          >
            {LL("Bt_Submit")}
          </MyButton>
        </Box>
      </form>
    </Box>
  );
};

export { FormEditStudent };
