import { Box, Typography } from "@mui/material";
import React from "react";
import { TeacherFAQList } from "./TeacherFAQList";
import { useResource } from "hooks/useResource";
import { AddTeacherFAQForm } from "./AddTeacherFAQForm";

type Props = {
  idCourse: string;
  idTeacher: string;
};

const TeacherFAQBlock: React.FC<Props> = ({
  idCourse,
  idTeacher
}) => {
  const { LL } = useResource();
  return (
    <Box>
      <Typography variant="h3" mt={4} mb={3}>
        {LL("Teacher_FAQ")}
      </Typography>
      <TeacherFAQList idCourse={idCourse} />
      <AddTeacherFAQForm idCourse={idCourse} idTeacher={idTeacher} />
    </Box>
  );
};

export { TeacherFAQBlock };
