import React from "react";
import { useResource } from "hooks/useResource";

import { TestimonialService } from "services/user/testimonial.service";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TestimonialDto } from "dto/user/testimonial.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box, Typography } from "@mui/material";

import { Carousel } from "components/elements/Carousel/Carousel";

import { CommonTools } from "tools/utils/commontools";
import { SkeletonScnReviews } from "components/elements/Skeleton/SkeletonSection";

import { BecomeTeacherTestimonialItem } from "components/user/testimonial/BecomeTeacherTestimonialItem";
import { useList } from "hooks/useList";

const service = new TestimonialService();

const BecomeTeacherTestimonialList: React.FC<PageComponentProps> = () => {
  // const { saveCache, getCache } = useResource();
  const { LL, CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<TestimonialDto>>([]);

  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 20;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));

  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);

  //   const data = await testimonialService.getList(undefined, {}, reqList);

  //   const rez = !data || !data.objects ? [] : data.objects;

  //   setObjects(rez);
  //   setIsLoading(false);

  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getList(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<TestimonialDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      parseInt(CC("testimonial_become_teacher_block", "20")),
      []
    ),
    [],
    true,
    "testimonial_become_teacher_block"
  );

  const processTestimonialItem = (obj: TestimonialDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <BecomeTeacherTestimonialItem obj={obj} />
      </Box>
    );
  };

  const processBecomeTeacherTestimonialList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowTestimonialCarouselBecomeTeacher", "3")),
      objects.length
    );

    return (
      <Box className="container" py={5}>
        <Typography variant="h2" textAlign="center">
          {LL("Extent_your_area")}
        </Typography>
        <Typography textAlign="center" className="subText" mt={2} mb={4}>
          {LL("We_changed_the_life_between_student_and_teacher")}
        </Typography>
        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: TestimonialDto, i: number) => {
            return processTestimonialItem(item, i);
          })}
        </Carousel>
      </Box>
    );
  };

  return loading ? (
    <SkeletonScnReviews />
  ) : (
    processBecomeTeacherTestimonialList()
  );
};

export { BecomeTeacherTestimonialList };
