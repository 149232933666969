import { PopulateTypeDto } from "dto/nomenclature/populatetype.dto";
import { SocialNewDto } from "dto/user/social.dto";
import React from "react";
import { FromSocialUserList } from "./FromSocialUserList";
import { Box } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { useResource } from "hooks/useResource";

type Props = {
  objectsTypeSocial: PopulateTypeDto[];
  objects: SocialNewDto[];
  setObjects: React.Dispatch<React.SetStateAction<SocialNewDto[] | null>>;
  idUser: string;
  onSubmit: (objects: SocialNewDto[]) => void;
  loading: boolean;
};

const FromSocialUser: React.FC<Props> = ({
  objectsTypeSocial,
  objects,
  onSubmit,
  idUser,
  setObjects,
  loading
}) => {
  const { LL } = useResource();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onSubmit(objects);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FromSocialUserList
        objects={SocialNewDto.parseObjectsForUpdates(
          idUser,
          objectsTypeSocial,
          objects
        )}
        setObjects={setObjects}
      />
      <Box textAlign="center">
        <MyButton
          tabIndex={0}
          disabled={loading}
          color="primary"
          variant="contained"
          size="large"
          className="btn"
          type="submit"
          sx={{ mx: 2, my: 2 }}
        >
          {LL("Bt_Submit")}
        </MyButton>
      </Box>
    </form>
  );
};

export { FromSocialUser };
