import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";

export class PostAddressDto implements Idto {
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;
}

export class AddressDto implements Idto {
  id?: string;
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;
}

export class AddressFormDto implements Idto {
  id?: string;
  status?: number;
  street?: string;
  zipcode?: string;
  streetnumber?: number;
  idlocality?: string;
  iduser?: string;
  locality?: any;

  constructor(
    id?: string,
    status?: number,
    street?: string,
    zipcode?: string,
    streetnumber?: number,
    idlocality?: string,
    iduser?: string,
    locality?: any
  ) {
    this.id = id || "";
    this.status = status || Status.ACTIVE;
    this.street = street || "";
    this.zipcode = zipcode || "";
    this.streetnumber = streetnumber || 0;
    this.idlocality = idlocality || "";
    this.iduser = iduser || "";
    this.locality = locality || null;
  }

  static parseFromAddressDto(obj?: AddressDto): AddressFormDto {
    if (!obj) return new AddressFormDto();

    return new AddressFormDto(
      obj.id,
      obj.status,
      obj.street,
      obj.zipcode,
      obj.streetnumber,
      obj.idlocality,
      obj.iduser,
      obj.locality
    );
  }
}
