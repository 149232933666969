import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import MyTextField from "components/form/mytextfield";
import CloseIcon from "@mui/icons-material/Close";
import RequiredValidator from "validators/required.validator";
import ValidateTools from "tools/utils/validate.tools";
import { useAuth } from "hooks/useAuth";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MyButton } from "components/elements/button/MyButton";

import { CircularLoading } from "components/elements/loading/CircularLoading";
import { PostTeacherRequestDto } from "dto/user/teacherrequest.dto";
import { TeacherRequestService } from "services/user/teacherrequest.service";
import { TeacherRequestButtonContent } from "./TeacherRequestButtonContent";
import { TeacherRequestButton } from "./TeacherRequestButton";
import { Status } from "tools//types/status";
import { CommonTools } from "tools/utils/commontools";

const service = new TeacherRequestService();
const TeacherRequestDialog: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();
  const { teacher } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [obj, setObj] = useState<PostTeacherRequestDto>(
    new PostTeacherRequestDto()
  );
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [objectWasChanged, setObjectWasChanged] = useState<string>("");

  const [validators, setValidators] = useState(false);
  const [vresults, setVResults] = useState<any>(false);

  const validateAll = () => {
    if (!obj) return;
    if (!validators) return;
    obj.teachermessage =
      obj.teachermessage !== undefined ? obj.teachermessage : "";
    setObj(obj);
    validateField("teachermessage", obj.teachermessage);
  };

  const validateField = (field: any, value: any, v?: any) => {
    v = v ? v : JSON.parse(JSON.stringify(vresults));
    if (v === false) v = {};
    v[field] = ValidateTools.validate(field, value, validators, vresults);
    setVResults(v);
    setIsFormDisabled(ValidateTools.vForm(v, field));
    return v;
  };

  const processValidators = () => {
    let v: any = {
      teachermessage: [],
    };

    v.teachermessage.push(new RequiredValidator("", ""));

    setValidators(v);
  };
  const checkLoading = () => {
    if (!loading) return;
    if (!obj) return;

    setLoading(false);
  };

  useEffect(() => {
    processValidators();
    validateAll();
  }, [currentRoute]);

  useEffect(() => {
    checkLoading();
  }, [obj, validators]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [vresults]);

  const setObjField = (field: any, value: any) => {
    let t = JSON.parse(JSON.stringify(obj));
    t[field] = value;
    setObj(t);

    validateField(field, value);
  };

  const checkIsFormDisabled = () => {
    var rez = ValidateTools.vForm(vresults, "teachermessage");

    setIsFormDisabled(rez);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isFormDisabled) return false;

    if (!obj || !teacher) return;

    obj.idteacher = teacher.id;

    setLoading(true);
    service.become(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO, cbparams: any) => {
    if (!result) return;
    if (!result.err) {
      setObjectWasChanged(CommonTools.generateRandomString(5));
      setOpen(false);
      setLoading(false);
      setObj(new PostTeacherRequestDto());
    }
  };

  const handleClose = () => {
    setOpen(false);
    setObj(new PostTeacherRequestDto());
  };

  const processForm = () => {
    if (!obj) return <></>;
    if (loading) return <CircularLoading />;

    return (
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6" mb={1}>
            {" "}
            {LL("Message")}
          </Typography>
          <MyTextField
            multiline
            minRows={4}
            tabIndex={0}
            fullWidth
            id="teachermessage"
            name="teachermessage"
            variant="standard"
            color="primary"
            className="form-input"
            _label={LL("teachermessage")}
            size="small"
            value={obj.teachermessage}
            setObj={setObjField}
            _vresults={
              vresults && vresults.teachermessage
                ? vresults.teachermessage
                : false
            }
          />
        </Box>

        <Box mt={3} sx={{ textAlign: "center" }}>
          <MyButton
            tabIndex={1}
            disabled={isFormDisabled}
            color="primary"
            variant="contained"
            size="large"
            type="submit"
            className="btn"
            children={LL("Bt_send_request")}
          />
        </Box>
      </form>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const processComponent = () => {
    if (!teacher) return <></>;
    if (!teacher.hasOwnProperty("status")) return <></>;
    if (!teacher.status) return <></>;
    if (teacher.status === Status.TEACHER_PRO) return <></>;
    return (
      <>
        <TeacherRequestButton
          cb={handleClickOpen}
          objectWasChanged={objectWasChanged}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          className="dialogAdd"
        >
          <DialogTitle>
            <TeacherRequestButtonContent obj={teacher} />
          </DialogTitle>
          <IconButton
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>{processForm()}</DialogContent>
        </Dialog>
      </>
    );
  };

  return processComponent();
};

export { TeacherRequestDialog };
