import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";

import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { RouteTools } from "tools/utils/routetools";
import RequestListDTO from "dto/app/requestlist.dto";

import RequestFilterDTO from "dto/app/requestfilter.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import { Box, Grid, Typography, Skeleton } from "@mui/material";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { MySortSelect } from "components/elements/Select/MySortSelect";
import { MyPagination } from "components/elements/MyPagination/MyPagination";
import { useAuth } from "hooks/useAuth";
import { useCookies } from "react-cookie";
import { CourseEnrollmentDto } from "dto/course/courseenrollment.dto";
import { CourseEnrollmentService } from "services/course/courseenrollment.service";
import { TeacherInterfaceStudentCard } from "./TeacherInterfaceStudentCard";
import { CourseByTeacherAutocomplete } from "components/elements/Autocomplete/CourseByTeacherAutocomplete";
import { NoResult } from "components/general/NoResult";

const service = new CourseEnrollmentService();

const TeacherInterfaceStudentList: React.FC<PageComponentProps> = ({
  currentRoute
}) => {
  const { LL, CC } = useResource();
  const { teacher } = useAuth();
  const mainUrl =
    currentRoute && currentRoute.url ? currentRoute.url.split("?") : "";
  const [isLoading, setIsLoading] = useState(true);
  const [objects, setObjects] = useState<Array<CourseEnrollmentDto>>([]);
  const [total, setTotal] = useState<number>(-1);
  const [totalPage, setTotalPage] = useState<number>(-1);
  const [responseParams, setResponseParams] = useState<any>({});
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("OnPageTeacherInterfaceStudentList", "10"))
  );
  const [page, setPage] = useState<number>(1);
  const [sortCriteria, setSortCriteria] = useState<
    Array<RequestSortCriteriaDTO>
  >(new Array<RequestSortCriteriaDTO>());
  const [cookies] = useCookies();
  const [filterIdCourse, setFilterIdCourse] = useState<string>("");
  const [defaultFilterIdCourse, setDefaultFilterIdCourse] =
    useState<string>("");

  const getList = () => {
    if (!teacher) return;
    setIsLoading(true);

    const reqList = new RequestListDTO();
    reqList.onpage = onPage;
    reqList.page = page;
    reqList.filters = [];
    reqList.sortcriteria = sortCriteria;

    let f: RequestFilterDTO;

    f = new RequestFilterDTO();
    f.field = "idteacher";
    f.values = [teacher.id];
    reqList.filters.push(f);

    if (filterIdCourse) {
      f = new RequestFilterDTO();
      f.field = "idcourse";
      f.values = [filterIdCourse];
      reqList.filters.push(f);
    }

    const urlPart = RouteTools.prepareListLocation(reqList);

    RouteTools.setHistory(mainUrl[0] + urlPart, {});
    service.getListForTeacher(loadObjects, {}, reqList);
  };
  const loadObjects = (result: ResultListDTO) => {
    if (!result) return;
    if (!result.err) {
      const objects = result.objects ? result.objects : [];
      const total = result.total !== undefined ? result.total : -1;
      const totalPage =
        result.totalpages !== undefined ? result.totalpages : -1;
      const reqInfo = result.requestinfo ? result.requestinfo : {};
      setObjects(objects);
      setTotal(total);
      setTotalPage(totalPage);
      setResponseParams(reqInfo);
    }
  };
  const checkLoading = () => {
    let load = false;

    if (total === -1) return true;
    if (totalPage === -1) return true;
    if (!objects) load = true;
    if (Object.keys(responseParams).length === 0) return true;

    setIsLoading(load);
  };
  const defaultSortCriteria = () => {
    if (!sortCriteria || sortCriteria.length === 0) {
      const sortCriteria = new RequestSortCriteriaDTO();
      sortCriteria.field = "data";
      sortCriteria.asc = false;
      setSortCriteria([sortCriteria]);
    }
  };

  useEffect(() => {
    if (sortCriteria && sortCriteria.length > 0) getList();
  }, [sortCriteria, onPage, page, filterIdCourse]);

  const processCourseFilter = () => {
    if (!teacher) return null;

    return (
      <Box>
        <CourseByTeacherAutocomplete
          valueFilter={filterIdCourse}
          setValueFilter={setFilterIdCourse}
          idTeacher={teacher.id}
          _defaultValue={defaultFilterIdCourse}
        />
      </Box>
    );
  };

  useEffect(() => {
    const reqListRoute = RouteTools.prepareListRequest(currentRoute, cookies);
    if (reqListRoute.page) setPage(reqListRoute.page);
    if (reqListRoute.filters) {
      if (reqListRoute.filters.length > 0) {
        const filterCourse = reqListRoute.filters.find(
          (f: RequestFilterDTO) => f.field === "idcourse"
        );
        if (filterCourse) {
          if (filterCourse.values && filterCourse.values.length > 0) {
            setFilterIdCourse(filterCourse.values[0]);
            setDefaultFilterIdCourse(filterCourse.values[0]);
          }
        }
      }
    }

    if (reqListRoute.sortcriteria && reqListRoute.sortcriteria.length > 0) {
      setSortCriteria(reqListRoute.sortcriteria);
    } else {
      defaultSortCriteria();
    }
  }, []);

  useEffect(() => {
    checkLoading();
  }, [objects]);

  const processItem = (obj: CourseEnrollmentDto, index: number) => {
    if (!obj) return null;

    return (
      <Box key={index}>
        <TeacherInterfaceStudentCard obj={obj} />
      </Box>
    );
  };

  const processPagination = () => {
    if (totalPage === -1) return null;
    if (total === -1) return null;
    if (objects.length === 0) return null;

    return (
      <Box
        className="container"
        py={4}
        display="flex"
        sx={{ justifyContent: "center" }}
      >
        <MyPagination
          page={page}
          total={total}
          totalPage={totalPage}
          setPage={setPage}
        />
      </Box>
    );
  };

  const processSortSelect = () => {
    const objects = ["data_asc", "data_desc"];
    return (
      <Box
        sx={{
          "& label": {
            display: "none",
          },
        }}
      >
        <MySortSelect
          objects={objects}
          setSortCriteria={setSortCriteria}
          sortCriteria={sortCriteria}
          variant="standard"
          className="form-select"
        />
      </Box>
    );
  };

  const processList = () => {
    if (!objects.length) return <NoResult identifier="student" />;
    return (
      <Box>
        {objects.map((item: CourseEnrollmentDto, i: number) => {
          return processItem(item, i);
        })}
      </Box>
    );
  };

  return (
    <Box className="container" py={3}>
      <Typography textAlign={"center"} variant="h3" mb={3}>
        {LL("My_students_list")}
      </Typography>
      <Grid container direction={"row"} spacing={2} mb={3}>
        <Grid item lg={3} xs={12}>
          {processSortSelect()}
        </Grid>
        <Grid item lg={3} xs={12}>
          {processCourseFilter()}
        </Grid>
      </Grid>

      {isLoading ? <Skeleton /> : processList()}
      {isLoading ? <Skeleton /> : processPagination()}
    </Box>
  );
};

export { TeacherInterfaceStudentList };
