import React from "react";
import { Box, Typography } from "@mui/material";
import { MyButton } from "components/elements/button/MyButton";
import { LocalStorageApi } from "api/localstorage.api";
import ErrorImage from "src/assets/images/errorImage.png";

const ServerError: React.FC = () => {
  const handleReload = () => {
    LocalStorageApi.saveObject("reload_count", []);
    window.location.reload();
  };
  return (
    // <Box
    //   sx={{
    //     width: "100%",
    //     height: "90vh",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     flexDirection: "column",
    //   }}
    // >
    //   <Box sx={{ fontSize: "150px", fontWeight: "500" }}>500</Box>
    //   <Box sx={{ fontSize: "30px" }}>Server Error</Box>
    //   <Box mt={3}>
    //     <MyButton cb={handleReload}>Reload</MyButton>
    //   </Box>
    // </Box>
    <Box className="dialogPages">
      {/* <Box className="errorCodeText">500</Box> */}
      {/* <Box sx={{ backgroundImage: `url(${ErrorImage})` }}>

      </Box> */}
      <Box className="imageErrorBox"></Box>
      <Box className="errorText">Server Error</Box>
      <Box className="errorTextSmall">Please wait a bit and try again.</Box>
      <Box mt={3} className="errorBoxButton">
        <MyButton cb={handleReload} className=" ">
          <Typography className="textButton">Reload Page</Typography>
          <Box className=" ">
            {/* <KeyboardArrowRightIcon className="iconSvg2" /> */}
          </Box>
        </MyButton>
      </Box>
    </Box>
  );
};

export { ServerError };
