import { Box, Typography, Grid } from "@mui/material";

import { CourseTeacherRequestDto } from "dto/course/courseteacherrequest.dto";

import { useResource } from "hooks/useResource";
import React from "react";

import { StatusChip } from "components/elements/Chip/StatusChip";
import { CommonTools } from "tools/utils/commontools";

type Props = {
  obj: CourseTeacherRequestDto;
};

const CourseTeacherInterfaceRequestItem: React.FC<Props> = ({ obj }) => {
  const { LL } = useResource();

  const processItem = () => {
    if (!obj) return null;

    return (
      <Box className="teacherRequest" my={2}>
        <Typography variant="h5">
          {CommonTools.processObjectField(obj, ["course", "name"])}
          <StatusChip obj={obj} />
        </Typography>
        <Grid container>
          <Grid item lg={6} sm={12}>
            <Typography color="text.disabled" variant="caption">
              {LL("Type")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["type_name"])}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {LL("Date")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["date_name"])}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {LL("teachermessage")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["teachermessage"])}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Typography color="text.disabled" variant="caption">
              {LL("changedate")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["changedate_name"])}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {LL("managerfullname")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["managerfullname"])}
            </Typography>
            <Typography color="text.disabled" variant="caption">
              {LL("managermessage")}
            </Typography>
            <Typography>
              {CommonTools.processObjectField(obj, ["managermessage"])}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return processItem();
};

export { CourseTeacherInterfaceRequestItem };
