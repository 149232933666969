import PageComponentProps from "interfaces/pagecomponentprops.interface";
import React from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";

import { CourseDto } from "dto/course/course.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { Box } from "@mui/material";
import { CourseCard } from "components/course/course/CourseCard";
import { Carousel } from "components/elements/Carousel/Carousel";
import { CommonTools } from "tools/utils/commontools";
import { Typography } from "@mui/material";
import { SkeletonCourseBlock } from "../../elements/Skeleton/Skeleton";
import { useList } from "hooks/useList";
const service = new CourseService();

const HomeCourseBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  // const { saveCache, getCache } = useResource();

  // const [loading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseDto>>([]);
  const { LL, CC } = useResource();
  // const getList = async () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = 100;
  //   reqList.page = 1;
  //   reqList.filters = [];

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);

  //   const cd = getCache(JSON.stringify(reqList));
  //   // logger("InfoCourseLevel getCache", cd);
  //   if (cd !== false) {
  //     setObjects(cd);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsLoading(true);
  //   // logger("InfoCourseLevel reqList", reqList);
  //   const data = await service.getListRecommendedHome(
  //     undefined,
  //     {},
  //     reqList
  //   );

  //   const rez = !data || !data.objects ? [] : data.objects;
  //   // logger("InfoCourseLevel data", data);
  //   setObjects(rez);
  //   setIsLoading(false);

  //   // logger("InfoCourseLevel rez", rez);
  //   saveCache(rez, JSON.stringify(reqList));
  // };

  // useEffect(() => {
  //   getList();
  // }, []);

  const getList = (cb?: any, cbParams?: any, reqList?: RequestListDTO) => {
    service.getListRecommendedHome(cb, cbParams, reqList);
  };

  const [loading, objects] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      50,
      []
    ),
    [],
    true,
    "home_course_block"
  );

  const processCourseItem = (obj: CourseDto, index: number) => {
    if (!obj) return null;
    return (
      <Box key={index}>
        <CourseCard obj={obj} loading={loading} />
      </Box>
    );
  };

  const processCourseList = () => {
    if (!objects) return null;
    if (!objects.length) return null;
    const slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("DefaultValueShowHomeCourseCarousel", "4")),
      objects.length
    );

    return (
      <Box className="container coursesHome">
        <Typography variant="h2" mb={3}>
          {LL("Recomandări pentru tine")}
        </Typography>
        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: CourseDto, i: number) => {
            return processCourseItem(item, i);
          })}
        </Carousel>
      </Box>
    );
  };

  return loading ? <SkeletonCourseBlock /> : processCourseList();
};

export { HomeCourseBlock };
