import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { TopCourseCategory } from "components/course/coursecategory/TopCourseCategory";
import { Box, Typography } from "@mui/material";
import { useResource } from "hooks/useResource";

const HomeCategoryBlock: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { LL } = useResource();
  return (
    <Box className="container">
      <Typography variant="h2" my={4}>
        {LL("Categorii de top")}
      </Typography>
      <TopCourseCategory currentRoute={currentRoute} />
    </Box>
  );
};

export { HomeCategoryBlock };
