import Idto from 'interfaces/idto.interface';
import { Status } from 'tools//types/status';

export class TeacherDto implements Idto {


  id?: string;
  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  rating?: number;
  name?: string;
  surname?: string;
  typeoccupation?: any;
  typediscipline?: any;
  numberofstudents?: number;
  numberofcourses?: number;
  numberoftestimonials?: number;
  fullurl?: string;
  fullname?: string;
  videolessonexample?: any;
  videopresentation?: any;
  attachmentcv?: any;
  status_name?: string;

  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }
  
}

export class PostTeacherDto implements Idto {

  iduser?: string;
  idtypespecialty?: string;
  idtypetargetgroup?: string;
  idtypeinternetconnection?: string;
  idtypehardware?: string;
  idtypelesson?: string;
  idtypediscipline?: string;
  idtypeoccupation?: string;
  idtypestudies?: string;
  idtypeprofessionalismlevel?: string;
  idtypeexperience?: string;
  bio?: string;
  timetoteachperweek?: string;
  hasaudience?: boolean;
  graduatedinstitution?: string;
  whyliveclass?: string;
  idssociallink?: string[];
  status?: number;
  idsterm?: string[];
  idvideolessonexample?: string;
  idvideopresentation?: string;
  idattachmentcv?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;
  showprofile?: boolean;
  showcourses?: boolean;
  rating?: number;
  name?: string;
  surname?: string;
  fullurl?: string;

  constructor() {
    this.status = Status.TEACHER_UNVERIFIED;
  }
}

export class TeacherMediaDto implements Idto {
  videolocationlessonexample?: string;
  videolocationpresentation?: string;
  idattachmentcv?: string;
  idteacher?: string;
  files?: any;
  fileInfoObj?: any;
  deleteattachmentcv?: boolean;
  deletevideopresentation?: boolean;
  deletevideolessonexample?: boolean;
}

export class TeacherNotificationDto implements Idto {
  iduser?: string;
  notificationtype1?: boolean;
  notificationtype2?: boolean;
  notificationtype3?: boolean;

  constructor(
    iduser?: string,
    notificationtype1?: boolean,
    notificationtype2?: boolean,
    notificationtype3?: boolean
  ) {
    this.iduser = iduser || "";
    this.notificationtype1 = notificationtype1 || false;
    this.notificationtype2 = notificationtype2 || false;
    this.notificationtype3 = notificationtype3 || false;
  }

  static fromTeacherDto(
    idUser?: string,
    obj?: TeacherDto
  ): TeacherNotificationDto {
    if (!obj) return new TeacherNotificationDto(idUser);
    return new TeacherNotificationDto(
      obj.iduser ?? idUser,
      obj.notificationtype1 ?? false,
      obj.notificationtype2 ?? false,
      obj.notificationtype3 ?? false
    );
  }
}