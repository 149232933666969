import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";

import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { CourseDto } from "dto/course/course.dto";

import { CommonTools } from "tools/utils/commontools";
import { ComponentCommonTools } from "tools/utils/componentcommontools";
import { useResource } from "hooks/useResource";

import { Box, Stack, Grid } from "@mui/material";

import { MyNavBt } from "components/elements/button/MyNavBt";

import DateTools from "tools/utils/date.tools";
import { StatusChipCourse } from "components/course/course/StatusChipCourse";
import { TypeCourseCardChip } from "components/course/course/TypeCourseCardChip";
import { Status } from "tools//types/status";
import { CourseEnrollmentStatus } from "components/course/courseenrollment/CourseEnrollmentStatus";
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";
type Props = {
  obj: CourseDto;
  loading: boolean;
  loadAction?: any;
};

const StudentCourseCard: React.FC<Props> = ({
  obj,
  loading,
  loadAction,
  ...props
}) => {
  const { LL } = useResource();
  const [imgUrl, setImgUrl] = useState<string>("");
  const [fullUrl, setFullUrl] = useState<string>("");

  const prepareGallery = () => {
    if (!obj) return;
    if (!obj.hasOwnProperty("gallery")) return;
    if (!obj.gallery) return;
    if (obj.gallery.length === 0) return;
    const galleryObj = CommonTools.getGalleryObjectLogo(obj.gallery);
    if (!galleryObj) return;
    CommonTools.setFileUrlsGallery(galleryObj, setImgUrl, setFullUrl, 310, 180);
  };

  useEffect(() => {
    prepareGallery();
  }, [obj]);

  const processImage = () => {
    if (!obj) return null
    if (!imgUrl) return null

    return (
      <CardMedia sx={{ height: 180 }} image={imgUrl} title="">
        {processCourseType()}
        {processCourseStatus()}
      </CardMedia>
    );
  };

  const processCourseType = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("coursesettings")) return null
    if (!obj.coursesettings) return null
    return (
      <Box p={1}>
        <TypeCourseCardChip obj={obj.coursesettings} width={14} height={14} />
      </Box>
    );
  };

  const processCourseStatus = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("status")) return null
    if (!obj.status) return null
    return (
      <Box p={1}>
        <StatusChipCourse obj={obj} />
      </Box>
    );
  };

  const processCoursePrice = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("courseenrollments")) return null
    if (!obj.courseenrollments) return null
    if (!obj.courseenrollments.paymentstatus) return null
    if (obj.courseenrollments.paymentstatus === Status.PAYMENT_PAID)
      return null
    if (!obj.hasOwnProperty("courseprice")) return null
    if (!obj.courseprice) return null
    if (!obj.courseprice.hasOwnProperty("totalprice")) return null
    if (!obj.courseprice.totalprice) return null
    if (!obj.courseprice.hasOwnProperty("priceperlesson")) return null
    if (!obj.courseprice.priceperlesson) return null
    if (!obj.courseprice.hasOwnProperty("currency_identifier")) return null
    if (!obj.courseprice.currency_identifier) return null
    return (
      <Stack direction="column">
        <Stack direction="row" spacing={1}>
          <Typography className="priceTotal">
            <span>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "courseprice",
                "totalprice"
              )}
            </span>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "courseprice",
              "currency_identifier"
            )}
          </Typography>
        </Stack>

        <Stack direction="row" className="pricePer">
          <Typography component="span">
            <span>
              {ComponentCommonTools.processObjectFieldOfObject(
                obj,
                "courseprice",
                "priceperlesson"
              )}
            </span>
            {ComponentCommonTools.processObjectFieldOfObject(
              obj,
              "courseprice",
              "currency_identifier"
            )}
          </Typography>
          <Typography component="span">/</Typography>
          <Typography component="span">{LL("per_lesson")}</Typography>
        </Stack>
      </Stack>
    );
  };

  const processCourseNextLesson = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("nextlessonstartdate")) return null
    if (!obj.nextlessonstartdate) return null
    return (
      <Box>
        <Typography component="span" className="lp">
          {LL("Next_lesson")}
        </Typography>
        <Typography p={1} component="span" className="rp">
          {DateTools.displayTimestampInDateAndHHMM(obj.nextlessonstartdate)}
        </Typography>
      </Box>
    );
  };

  const processCourseDetails = () => {
    if (!obj) return null

    return (
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Stack direction="column" className="detailsGeneral">
            <Box>
              <Typography component="span" className="lp">
                {LL("Language course")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldOfObject(
                  obj,
                  "language",
                  "name"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("CourseDuration")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectFieldTimeStamp(
                  obj,
                  "courseduration"
                )}
              </Typography>
            </Box>
            <Box>
              <Typography component="span" className="lp">
                {LL("NumberOfLessons")}
              </Typography>
              <Typography p={1} component="span" className="rp">
                {ComponentCommonTools.processObjectField(
                  obj,
                  "numberoflessons"
                )}
              </Typography>
            </Box>
            {processNumberOfLessonsRemaining()}
          </Stack>
        </Stack>
      </Box>
    );
  };

  const processNumberOfLessonsRemaining = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("numberoflessonsremaining")) return null
    if (!obj.numberoflessonsremaining) return null
    return (
      <Box>
        <Typography component="span" className="lp">
          {LL("NumberOfLessonsRemaining")}
        </Typography>
        <Typography p={1} component="span" className="rp">
          {ComponentCommonTools.processObjectField(
            obj,
            "numberoflessonsremaining"
          )}
        </Typography>
      </Box>
    );
  };

  const processName = () => {
    if (!obj) return null
    return (
      <Typography className="name">
        {ComponentCommonTools.processObjectField(obj, "name")}
      </Typography>
    );
  };

  const processButtonGoToCourse = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("courseenrollments")) return null
    if (!obj.courseenrollments) return null
    if (!obj.courseenrollments.hasOwnProperty("id")) return null
    if (!obj.courseenrollments.id) return null
    const url = "/profile/courseenrollment/" + obj.courseenrollments.id;
    return (
      <MyNavBt href={url} className="goToClass">
        <KeyboardDoubleArrowRightOutlinedIcon />
      </MyNavBt>
    );
  };
  const processStudentCourseCard = () => {
    if (!obj) return null

    return (
      <Box py={1}>
        <Card className="courseCardH teacherCards">
          <Grid container className="content" spacing={2} alignItems="center">
            <Grid item lg={2} sm={12}>
              {processImage()}
            </Grid>
            <Grid item lg={5} sm={12}>
              {processName()}

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                alignContent="center"
                className="buttons"
                mt={3}
                spacing={2}
              >
                {processCourseStatus()}
                {processCoursePrice()}
              </Stack>
            </Grid>
            <Grid item lg={4} sm={12} className="dtP">
              {processCourseNextLesson()}
              {processCourseDetails()}
            </Grid>
            <Grid
              item
              lg={1}
              sm={12}
              p={1}
              justifyContent="flex-end"
              display="flex"
            >
              {processButtonGoToCourse()}
            </Grid>
          </Grid>
        </Card>
      </Box>
    );
  };

  const processEnrollment = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("courseenrollments")) return null
    if (!obj.courseenrollments) return null
    return <CourseEnrollmentStatus obj={obj.courseenrollments} />;
  };
  const processCard = () => {
    if (!obj) return null
    if (!obj.hasOwnProperty("courseenrollments")) return null
    if (!obj.courseenrollments) return null
    if (!obj.courseenrollments.status) return null
    if (
      obj.courseenrollments.status === Status.ENR_COURSE_READY ||
      obj.courseenrollments.status === Status.ACTIVE ||
      obj.courseenrollments.status === Status.ENT_COURSE_STARTED ||
      obj.courseenrollments.status === Status.ENR_ARCHIVED ||
      obj.courseenrollments.status === Status.ENR_NEED_END_TEST ||
      obj.courseenrollments.status === Status.ENR_NEED_CERTIFICATE ||
      obj.courseenrollments.status === Status.ENR_COURSE_FINISHED ||
      obj.courseenrollments.status === Status.ENR_PASSED_END_TEST ||
      obj.courseenrollments.status === Status.ENR_WITH_CERTIFICATE ||
      obj.courseenrollments.status === Status.ENR_NEED_START_TEST
    ) {
      return processStudentCourseCard();
    } else {
      return (
        <Box py={1}>
          <Card className="courseCardH teacherCards">
            <Grid container className="content" spacing={2} alignItems="center">
              <Grid item lg={2} sm={12}>
                {processImage()}
              </Grid>
              <Grid item lg={5} sm={12}>
                {processName()}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                  className="buttons"
                  mt={3}
                  spacing={2}
                >
                  {processCourseStatus()}
                  {processCoursePrice()}
                </Stack>
              </Grid>
              <Grid item lg={4} sm={12} className="dtP">
                {processEnrollment()}
              </Grid>
              <Grid
                item
                lg={1}
                sm={12}
                p={1}
                justifyContent="flex-end"
                display="flex"
              >
                {processButtonGoToCourse()}
              </Grid>
            </Grid>
          </Card>
        </Box>
      );
    }
  };

  return loading ? <Skeleton variant="rectangular" /> : processCard();
};

export { StudentCourseCard };
