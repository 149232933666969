import { AxiosInstance } from "axios";
import MessageDto from "dto/app/message.dto";
import RequestListDTO from "dto/app/requestlist.dto";

import { isTokenExpired } from "tools/utils/commontools";
import { MessageTypes } from "tools/types/messagetypes";
import { generateTokenUrl } from "./user/user.repository";
import { getInstance } from "api/server.api";

const processAxiosResult = (data: any): any => {
  if (
    data &&
    data != undefined &&
    data != null &&
    data.meta &&
    data.meta != undefined &&
    data.meta != null
  ) {
    if (GeneralRepository.metaToolsF) {
      GeneralRepository.metaToolsF(data.meta);
    }
  }
  if (
    data &&
    data != undefined &&
    data != null &&
    data.messages &&
    data.messages != undefined &&
    data.messages != null
  ) {
    if (GeneralRepository.messagesF) {
      for (var i in data.messages) {
        GeneralRepository.messagesF(data.messages[i]);
      }
    }
  }
};

const processAxiosSuccess = (
  data: any,
  url: string,
  cb?: any,
  options?: any,
  cbparameters?: any
): any => {
  const result = data.data ? data.data : null;

  processAxiosResult(result);

  if (!cb || cb == undefined || cb == null) return result;

  if (!result) {
    GeneralRepository.handleServerError()
    return;
  }
  cb(result, cbparameters, data);

  return result;
};
const isResponse500 = (error: any) => {
  return error.response.status >= 500 && error.response.status <= 550;
};
const processAxiosError = (
  error: any,
  url: string,
  cb?: any,
  options?: any,
  cbparameters?: any
): any => {
  if (error && error.code === "ERR_NETWORK") {
    GeneralRepository.handleServerError()
    return;
  }
  if (
    error &&
    error != undefined &&
    error != null &&
    error.response &&
    error.response != undefined &&
    error.response != null &&
    error.response.data &&
    error.response.data != undefined &&
    error.response.data != null
  ) {
    if (error.response && !isResponse500(error)) {
      processAxiosSuccess(error.response.data, url, cb, options, cbparameters);
    }
    if (isResponse500(error)) {
      // logger("processAxiosError--------", error.response.status);
      const obj = new MessageDto();
      obj.code = error.response.status;
      obj.message = error.response.statusText;
      obj.mestype = MessageTypes.MESSAGE_ERROR;

      if (GeneralRepository.messagesF) {
        GeneralRepository.messagesF(obj);
      }

      GeneralRepository.handleServerError()
    } else {
      if (error.response.data.messages) {
        if (GeneralRepository.messagesF) {
          for (var i in error.response.data.messages) {
            GeneralRepository.messagesF(error.response.data.messages[i]);
          }
        }
      }
      if (error.response.status === 401) {
        GeneralRepository.unauthorized();
      }
    }
  }

  return "";
};

type Instance = {
  instance?: AxiosInstance;
  canExecute: boolean;
};

const processError = (cb?: any, cbParams?: any) => {
  const obj = new MessageDto();
  obj.code = "401";
  obj.message = "UNAUTHORIZED";
  obj.mestype = MessageTypes.MESSAGE_ERROR;

  if (GeneralRepository.messagesF) {
    GeneralRepository.messagesF(obj);
  }
  // if (GeneralRepository.handleIsServerError()) {
  //   GeneralRepository.handleServerError();
  //   return;
  // }
  GeneralRepository.unauthorized();

  if (!cb) return;
  cb(cbParams);
};

export default class GeneralRepository {
  private static idLanguage: any = false;
  private static tokenF: any = false;
  private static recaptchaTokenF: any = false;
  public static metaToolsF: any = false;
  public static messagesF: any = false;
  public static languageCode: any = false;
  public static reloadFunction: any = false;
  public static handleUnauthorized: any = false;
  public static serverError: any = false;
  public static isServerError: any = false;

  static setIsServerError(_isServerError: any) {
    GeneralRepository.isServerError = _isServerError;
  }

  static handleIsServerError() {
    if (GeneralRepository.isServerError) {
      GeneralRepository.isServerError();
    }
    return true;
  }

  static setIdLanguage(id: any) {
    GeneralRepository.idLanguage = id;
  }

  public static setServerError(_ne: any) {
    this.serverError = _ne;
  }

  static setLanguageCode(code: any) {
    GeneralRepository.languageCode = code;
  }

  static setRecaptchaToken(f: any) {
    GeneralRepository.recaptchaTokenF = f;
  }

  static setToken(f: any) {
    GeneralRepository.tokenF = f;
  }

  static setMetaToolsF(metaF: any) {
    GeneralRepository.metaToolsF = metaF;
  }

  static setMessagesF(mF: any) {
    GeneralRepository.messagesF = mF;
  }
  public static setReloadFunction(_rf: any) {
    this.reloadFunction = _rf;
  }

  public static setHandleUnauthorized(_hu: any) {
    this.handleUnauthorized = _hu;
  }

  public static unauthorized = () => {
    if (GeneralRepository.handleUnauthorized) {
      GeneralRepository.handleUnauthorized();
    }
  };
  // ---------------------------------------

  static getToken() {
    if (GeneralRepository.tokenF) {
      return GeneralRepository.tokenF();
    }
    return "";
  }

  static getRecaptchaToken() {
    if (GeneralRepository.recaptchaTokenF) {
      return GeneralRepository.recaptchaTokenF();
    }
    return "";
  }

  static handleServerError() {
    if (GeneralRepository.serverError) GeneralRepository.serverError();
  }

  static getIdLanguage() {
    if (GeneralRepository.idLanguage) {
      return GeneralRepository.idLanguage;
    }
    return "";
  }

  static getLanguageCode() {
    if (GeneralRepository.languageCode) {
      return GeneralRepository.languageCode();
    }
    return "";
  }
  // ---------------------------------------

  getAxiosInstance(url: string, options?: any, cbparameters?: any): Instance {
    const instanceValue: Instance = {
      canExecute: true,
    };
    const h: any = {};
    h["Content-Type"] = "application/json";

    const recaptchaToken = GeneralRepository.getRecaptchaToken();
    //
    if (recaptchaToken) {
      h["recaptchaToken"] = recaptchaToken;
    }

    const token = GeneralRepository.getToken();
    // logger("handleSetToken signInHash service repo postAxios instance", token);
    if (isTokenExpired(token)) instanceValue.canExecute = false;
    if (token) {
      h["Authorization"] = "Bearer " + token;
    } else if (cbparameters && cbparameters.jwt) {
      if (isTokenExpired(cbparameters.jwt)) instanceValue.canExecute = false;
      h["Authorization"] = "Bearer " + cbparameters.jwt;
    }

    const idLanguage = GeneralRepository.getIdLanguage();
    if (idLanguage) {
      h["idLanguage"] = idLanguage;
    }

    const languageCode = GeneralRepository.getLanguageCode();

    if (languageCode) {
      h["languageCode"] = languageCode;
      // // logger("getAxiosInstance.languageCode", h);
    }

    // const instance = axios.create({
    //   baseURL: url,
    //   //   timeout: 1000,
    //   headers: h,
    // });
    options.headers = h;
    instanceValue.instance = getInstance(options);
    return instanceValue;
  }

  // ---------------------------------------

  async getAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    //

    options = options ? options : {};

    // const h: any = {};
    // const token = GeneralRepository.getToken();
    // let canExecute = true;
    // if (token) {
    //   if (isTokenExpired(token)) canExecute = false;
    //   h["Authorization"] = "Bearer " + token;
    // } else if (
    //   cbparameters != undefined &&
    //   cbparameters.jwt != undefined &&
    //   cbparameters.jwt
    // ) {
    //   if (isTokenExpired(cbparameters.jwt)) canExecute = false;
    //   h["Authorization"] = "Bearer " + cbparameters.jwt;
    // }

    // const idLanguage = GeneralRepository.getIdLanguage();
    // if (idLanguage) {
    //   h["idLanguage"] = idLanguage;
    // }

    // options.headers = h;

    const instance = this.getAxiosInstance(url, options, cbparameters);
    const axiosInstance = instance.instance;
    // logger("Instance", instance);
    if (!axiosInstance) return;
    if (!instance.canExecute && url !== generateTokenUrl) {
      processError(cb, cbparameters);
      return;
    }
    try {
      const response = await axiosInstance.get(url, options);
      return processAxiosSuccess(response, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }

    // return await axios
    //   .get(url, options)
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});
  }

  async postAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance(url, options);

    const axiosInstance = instance.instance;

    // logger(
    //   "handleSetToken signInHash service repo postAxios",
    //   url,
    //   cb,
    //   options,
    //   cbparameters,
    //   axiosInstance,
    //   instance
    // );

    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // logger(
    //   "handleSetToken signInHash service repo postAxios",
    //   url,
    //   cb,
    //   options,
    //   cbparameters,
    //   axiosInstance,
    //   instance
    // );
    // return axiosInstance
    //   .post(url, JSON.stringify(options))
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.post(url, JSON.stringify(options));
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }
  //----------------------------------------
  async postAxiosMultipart(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance(url, options);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }

    // return axiosInstance
    //   .post(url, options, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.post(url, options, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }
  // ---------------------------------------
  async putAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance(url, options);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .put(url, JSON.stringify(options))
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.put(url, JSON.stringify(options));
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }
  async deleteAxios(url: string, cb?: any, cbparameters?: any): Promise<any> {
    const instance = this.getAxiosInstance(url, {});

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .delete(url)
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, undefined, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, undefined, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.delete(url);

      return processAxiosSuccess(result, url, cb, undefined, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, undefined, cbparameters);
    }
  }

  // ---------------------------------------
  async putAxiosMultipart(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance(url, options);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .put(url, options, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   })
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.put(url, options, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }
  // -------------------------------------
  async patchAxios(
    url: string,
    cb?: any,
    options?: any,
    cbparameters?: any
  ): Promise<any> {
    const instance = this.getAxiosInstance(url, options);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbparameters);
      return;
    }
    // return axiosInstance
    //   .patch(url, JSON.stringify(options))
    //   .then(function (data: any) {
    //     return processAxiosSuccess(data, url, cb, options, cbparameters);
    //   })
    //   .catch(function (error: any) {
    //     return processAxiosError(error, url, cb, options, cbparameters);
    //   })
    //   .finally(function () {});

    try {
      const result = await axiosInstance.patch(url, JSON.stringify(options));
      return processAxiosSuccess(result, url, cb, options, cbparameters);
    } catch (error) {
      return processAxiosError(error, url, cb, options, cbparameters);
    }
  }

  // ---------------------------------------

  static processListData(data?: RequestListDTO) {
    var rez: any = {};
    rez.params = {};

    data = data ?? new RequestListDTO();

    if (data.page != null && data.page !== undefined) {
      rez.params.page = data.page;
    }

    if (data.onpage != null && data.onpage !== undefined) {
      rez.params.onpage = data.onpage;
    }

    if (
      data.sortcriteria != null &&
      data.sortcriteria !== undefined &&
      data.sortcriteria.length
    ) {
      let str = "";
      for (const i in data.sortcriteria) {
        str += str ? "|" : "";
        str += data.sortcriteria[i].field;
        str += ",";
        str += data.sortcriteria[i].asc ? "asc" : "";
      }
      rez.params.order = str;
    }

    if (
      data.filters != null &&
      data.filters !== undefined &&
      data.filters.length
    ) {
      let str = "";
      for (const i in data.filters) {
        str += str ? "|" : "";
        str += data.filters[i].field;
        str += ",";
        str += data.filters[i].values ? data.filters[i].values?.join(",") : "";
      }
      rez.params.filters = str;
    }

    if (
      data.criteria != null &&
      data.criteria !== undefined &&
      data.criteria.length
    ) {
      let str = "";
      for (const i in data.criteria) {
        str += str ? "|" : "";
        str += data.criteria[i].id;
        str += ",";
        str += data.criteria[i].values
          ? data.criteria[i].values?.join(",")
          : "";
      }
      rez.params.criteria = str;
    }

    if (data.range != null && data.range !== undefined && data.range.length) {
      let str = "";
      for (const i in data.range) {
        str += str ? "|" : "";
        str += data.range[i].id;
        str += ",";
        str += data.range[i].values ? data.range[i].values?.join(",") : "";
      }
      rez.params.range = str;
    }

    return rez;
  }

  async _get(url: string, cb?: any, cbparameters?: any): Promise<any> {
    return await this.getAxios(url, cb, {}, cbparameters);
  }

  async _getList(
    url: string,
    cb?: any,
    cbparameters?: any,
    data?: RequestListDTO
  ): Promise<any> {
    const _data = GeneralRepository.processListData(data);
    return await this.getAxios(url, cb, _data, cbparameters);
  }
}
