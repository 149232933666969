import React from "react";
import { useResource } from "hooks/useResource";
import { useAuth } from "hooks/useAuth";
import PageComponentProps from "interfaces/pagecomponentprops.interface";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import { AuthMethodsBlock } from "components/auth/AuthMethodsBlock";
import Container from "@mui/material/Container";

import { FormSignUp } from "components/auth/FormSignUp";

const SignUp: React.FC<PageComponentProps> = () => {
  const { signUp } = useAuth();

  const { LL } = useResource();

  return (
    <Container maxWidth="sm">
      <Typography variant="h2" align="center" sx={{ my: 5 }}>
        {LL("SignUp")}
      </Typography>
      <Container maxWidth="xs" sx={{ p: 0 }}>
        <Box
          sx={{
            alignItems: "center",
            alignContent: "center",
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <FormSignUp onSubmit={signUp} />
          <Typography className="text-strike" my={4}>
            {LL("I have already an account")}
          </Typography>
          <AuthMethodsBlock isLoginPage={false} />
        </Box>
      </Container>
    </Container>
  );
};

export { SignUp };
