import React from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import { useResource } from "hooks/useResource";

import Box from "@mui/material/Box";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "components/elements/Tabs/TabPanel";

import { PopularCourses } from "components/course/course/PopularCourses";
import { TendencyCourses } from "components/course/course/TendencyCourses";

const CourseTabsPanelBlock: React.FC<PageComponentProps> = ({
  currentRoute,
}) => {
  const { LL } = useResource();

  const [value, setValue] = React.useState("popularCourses");

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  return (
    <Box className="container">
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        value={value}
        scrollButtons={false}
        onChange={handleChange}
        className="tabCourse"
        sx={{ mb: 2 }}
      >
        <Tab
          className="name"
          label={LL("Popular_Courses")}
          value={"popularCourses"}
        />
        <Tab
          className="name"
          label={LL("Tendency_Courses")}
          value={"tendencyCourses"}
        />
      </Tabs>

      <Box>
        <TabPanel value={value} index={"popularCourses"}>
          <PopularCourses currentRoute={currentRoute} />
        </TabPanel>
        <TabPanel value={value} index={"tendencyCourses"}>
          <TendencyCourses currentRoute={currentRoute} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export { CourseTabsPanelBlock };
