import React, { useCallback, useLayoutEffect } from "react";

import { useEffect, useState } from "react";

import { CookiesProvider } from "react-cookie";

import { ResourceProvider } from "providers/ResourceProvider";

import { AuthProvider } from "providers/AuthProvider";
import { HeadProvider } from "providers/HeadProvider";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { MessageProvider } from "providers/MessageProvider";
import { AppRoutes } from "providers/AppRoutes";
import { MyGoogleOAuthProvider } from "providers/MyGoogleOAuthProvider";
import { StudentCourseProvider } from "context/studentcourse.context";
import { ClassroomProvider } from "context/classroom.context";
import { CourseSearchProvider } from "context/coursesearch.context";
import GeneralRepository from "repositories/general.repository";
import { ServerError } from "components/general/ServerError";


const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  useEffect(() => {
    setTheme(theme);
  }, []);

  const isServerError = useCallback(() => {
    return serverError;
  }, [serverError]);

  const processServerError = () => {
    setServerError(true);
  };

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useLayoutEffect(() => {
    GeneralRepository.setReloadFunction(handleReload);
    GeneralRepository.setServerError(processServerError);
  }, []);

  useEffect(() => {
    GeneralRepository.setIsServerError(isServerError);
  }, [isServerError]);
  

  if (serverError)
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );

  return (
    <MyGoogleOAuthProvider>
      <ThemeProvider theme={_theme}>
        <CookiesProvider>
          <HeadProvider>
            <AuthProvider>
              <ResourceProvider>
                <MessageProvider>
                  <CourseSearchProvider>
                    <StudentCourseProvider>
                      <ClassroomProvider>
                        <AppRoutes />
                      </ClassroomProvider>
                    </StudentCourseProvider>
                  </CourseSearchProvider>
                </MessageProvider>
              </ResourceProvider>
            </AuthProvider>
          </HeadProvider>
        </CookiesProvider>
      </ThemeProvider>
    </MyGoogleOAuthProvider>
  );
};

export default App;
