import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

type MyTextFieldProps = {
  [x: string]: any;
  _label: any;
  setObj: any;
  _vresults: any;
  _containerClass?: any;
};

const MyTextField: React.FC<MyTextFieldProps> = ({
  _label,
  setObj,
  _vresults,
  _containerClass,
  ...props
}) => {
  const [label, setLabel] = useState("");
  const [field, setField] = useState("");
  const [vresults, setVResults] = useState<any>();

  useEffect(() => {
    setLabel(_label);
  }, [_label]);

  useEffect(() => {
    setVResults(_vresults);
  }, [_vresults]);

  useEffect(() => {
    const f = props.field ? props.field : props.name;
    setField(f);
  }, [props]);

  const handleChange = (event: any) => {
    if (!setObj) return;
    setObj(field, event.target.value);
  };

  const getContainerClassName = () => {
    const prefix: string = _containerClass ? _containerClass.toString() : "";
    if (!vresults) return prefix;
    if (vresults.isOk == "-1") return prefix;
    if (vresults.isOk == false) return prefix + " form-danger";
    else return prefix + " form-alert";
  };

  const infoMessages = () => {
    if (!vresults) return null;
    if (!vresults.objects) return null;
    if (!vresults.objects.length) return null;
    if (vresults.isOk == "-1") return null;

    const cl = " infoMessage " + (vresults.isOk ? "active" : "disabled");

    return vresults.objects.map((_obj: any, i: any) => {
      if (!_obj.message) return null;

      return (
        <span className={cl} key={i}>
          {_obj.message}
        </span>
      );
    });
  };

  return (
    <div className={getContainerClassName()}>
      <TextField
        label={label}
        onFocus={handleChange}
        onChange={handleChange}
        {...props}
      />
      {infoMessages()}
    </div>
  );
};

export default MyTextField;
