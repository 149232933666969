import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import MyCKEditor from "components/elements/ckeditor/MyCKEditor";
import { DisciplineTypeSelect } from "components/elements/Select/DisciplineTypeSelect";
import { ExperienceTypeSelect } from "components/elements/Select/ExperienceTypeSelect";
import { HardwareTypeSelect } from "components/elements/Select/HardwareTypeSelect";
import { InternetConnectionTypeSelect } from "components/elements/Select/InternetConnectionTypeSelect";
import { LessonTypeSelect } from "components/elements/Select/LessonTypeSelect";
import { OccupationTypeSelect } from "components/elements/Select/OccupationTypeSelect";
import { ProfessionalismLevelTypeSelect } from "components/elements/Select/ProfessionalismLevelTypeSelect";
import { SpecialtyTypeSelect } from "components/elements/Select/SpecialtyTypeSelect";
import { StudyTypeSelect } from "components/elements/Select/StudyTypeSelect";
import { TargetGroupTypeSelect } from "components/elements/Select/TargetGroupTypeSelect";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import MyTextField from "components/form/mytextfield";
import { MyButton } from "components/elements/button/MyButton";

import { BecomeTeacherDto } from "dto/user/becometeacher.dto";
import { useForm } from "hooks/useForm";
import { useResource } from "hooks/useResource";
import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: BecomeTeacherDto;
  onSubmit: (object: BecomeTeacherDto) => void;
  loading: boolean;
};

const FormBecomeTeacher: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useResource();
  const steps = [LL("Step_1"), LL("Step_2"), LL("Step_3")];
  const [activeStep, setActiveStep] = useState(0);
  const [term, setTerm] = useState(false);
  const [obj, disabled, setObjField] = useForm<BecomeTeacherDto>(
    defaultObject,
    RequiredValidator.getValidators(["email"])
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <MyTextField
              fullWidth
              id="timetoteachperweek"
              name="timetoteachperweek"
              variant="standard"
              className="form-input"
              _label={LL("timetoteachperweek")}
              value={obj.timetoteachperweek}
              setObj={setObjField}
              _vresults={undefined}
            />

            <Box mt={3}>
              <MyTextField
                fullWidth
                id="whyliveclass"
                name="whyliveclass"
                variant="standard"
                className="form-input"
                _label={LL("whyliveclass")}
                value={obj.whyliveclass}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box mt={3}>
              <MyTextField
                fullWidth
                id="graduatedinstitution"
                name="graduatedinstitution"
                variant="standard"
                className="form-input"
                _label={LL("graduatedinstitution")}
                value={obj.graduatedinstitution}
                setObj={setObjField}
                _vresults={undefined}
              />
            </Box>
            <Box mt={3}>
              <ExperienceTypeSelect
                value={obj.idtypeexperience}
                field="idtypeexperience"
                setObjectField={setObjField}
              />
            </Box>

            <Box mt={3}>
              <MyCKEditor
                data={obj.bio ? obj.bio : ""}
                setObj={setObjField}
                name="bio"
              />
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box mt={3}>
              <StudyTypeSelect
                value={obj.idtypestudies}
                field="idtypestudies"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <SpecialtyTypeSelect
                value={obj.idtypespecialty}
                field="idtypespecialty"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <MyCheckboxField
                label={LL("hasaudience")}
                field="hasaudience"
                setObjectField={setObjField}
                checked={obj.hasaudience}
              />
            </Box>
            <Box mt={3}>
              <TargetGroupTypeSelect
                value={obj.idtypetargetgroup}
                field="idtypetargetgroup"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <InternetConnectionTypeSelect
                value={obj.idtypeinternetconnection}
                field="idtypeinternetconnection"
                setObjectField={setObjField}
              />
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Box mt={3}>
              <HardwareTypeSelect
                value={obj.idtypehardware}
                field="idtypehardware"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <LessonTypeSelect
                value={obj.idtypelesson}
                field="idtypelesson"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <OccupationTypeSelect
                value={obj.idtypeoccupation}
                field="idtypeoccupation"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <DisciplineTypeSelect
                value={obj.idtypediscipline}
                field="idtypediscipline"
                setObjectField={setObjField}
              />
            </Box>
            <Box mt={3}>
              <ProfessionalismLevelTypeSelect
                value={obj.idtypeprofessionalismlevel}
                field="idtypeprofessionalismlevel"
                setObjectField={setObjField}
              />
            </Box>
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Box className="container" sx={{ maxWidth: 650 }} mt={5} mb={5}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Box my={5}>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {LL("Condition_Terms_Become_Teacher")}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <MyButton cb={handleBack} sx={{ mr: 1 }}>
              {LL("Back")}
            </MyButton>
            <Box>
              <MyCheckboxField
                label={LL("term")}
                field="term"
                setObjectField={(field, value) => setTerm(value)}
                checked={term}
                color="primary"
                size="small"
              />
            </Box>
            <MyButton
              tabIndex={4}
              cb={handleSubmit}
              disabled={disabled}
              color="primary"
              fullWidth
              variant="contained"
              size="large"
              type="submit"
              sx={{ mt: 4 }}
              children={LL("Bt_Submit")}
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ mt: 5 }}>{getStepContent(activeStep)}</Box>

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            mt={5}
            justifyContent="space-between"
          >
            <MyButton
              disabled={activeStep === 0}
              cb={handleBack}
              className="btn pxBnt"
              variant="outlined"
            >
              {LL("Back")}
            </MyButton>
            <MyButton cb={handleNext} className="btn pxBnt" variant="contained">
              {activeStep === steps.length - 1 ? LL("Finish") : LL("Next")}
            </MyButton>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export { FormBecomeTeacher };
