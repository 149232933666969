import ResultObjectDTO from "dto/app/resultobject.dto";
import { TicketDto } from "dto/support/ticket.dto";
import {
  ResponseTicketDto,
  ResponseTicketStudentDto,
  ResponseTicketTeacherDto,
  TicketMessageDto,
} from "dto/support/ticketmessage.dto";
import { StudentDto } from "dto/user/student.dto";
import { TeacherDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";
import React, { useState } from "react";
import { TicketMessageService } from "services/support/ticketmessage.service";
import { Types } from "tools/types/types";
import { CommonTools } from "tools/utils/commontools";
import { FormTicketResponse } from "./FormTicketResponse";
import { TicketService } from "services/support/ticket.service";

type Props = {
  type: number;
  ticket: TicketDto;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  updateArray: (object: TicketDto) => void;
  updateObjects: (newObj: TicketMessageDto) => void;
};

const service = new TicketMessageService();
const ticketService = new TicketService();

const createObject = (
  type: number,
  ticket: TicketDto,
  student?: StudentDto,
  teacher?: TeacherDto
) => {
  if (type === Types.TYPE_TICKET_STUDENT) {
    if (!student) return null;
    return new ResponseTicketStudentDto(
      CommonTools.processObjectField(ticket, ["id"]),
      CommonTools.processObjectField(student, ["id"])
    );
  } else {
    if (!teacher) return null;
    return new ResponseTicketTeacherDto(
      CommonTools.processObjectField(ticket, ["id"]),
      CommonTools.processObjectField(teacher, ["id"])
    );
  }
};
const TicketResponse: React.FC<Props> = ({
  type,
  ticket,
  updateObjects,
  loading,
  setLoading,
  updateArray,
}) => {
  const { student, teacher } = useAuth();
  const [obj, setObj] = useState(createObject(type, ticket, student, teacher));

  const onSubmit = (
    obj: ResponseTicketStudentDto | ResponseTicketTeacherDto
  ) => {
    setLoading(true);
    setObj(createObject(type, ticket, student, teacher));
    if (type === Types.TYPE_TICKET_STUDENT)
      service.responseByStudent(handleResult, {}, obj);
    else service.responseByTeacher(handleResult, {}, obj);
  };

  const handleResult = (result: ResultObjectDTO) => {
    if (!result) return;
    if (!result.err) {
      const obj: ResponseTicketDto | null = result.obj
        ? (result.obj as ResponseTicketDto)
        : null;
      if (!obj) return;
      updateObjects(service.populateDto(obj.ticketmessage));
      updateArray(ticketService.populateDto(obj.ticket));
      setLoading(false);
    }
  };
  if (!obj) return null;
  return (
    <FormTicketResponse
      loading={loading}
      defaultObj={obj}
      onSubmit={onSubmit}
    />
  );
};

export { TicketResponse };
