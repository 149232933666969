
import Idto from 'interfaces/idto.interface';
import { Status } from 'tools/types/status';
import { Config } from 'tools/utils/config';

export default class PhoneDto implements Idto {
    id?: string;
    phonenumber?: string;
    countrycode?: string;
    status?: number;
    activationcode?: string;
    idtypephone?: string;
    typephone?: any;
    iduser?: string;

}

export class PhoneFormDto implements Idto {
    id?: string;
    phonenumber?: string;
    countrycode?: string;
    status?: number;
    activationcode?: string;
    idtypephone?: string;
    iduser?: string;

    constructor(
        id?: string,
        phonenumber?: string,
        countrycode?: string,
        status?: number,
        activationcode?: string,
        idtypephone?: string,
        iduser?: string
    ) {
        this.id = id || '';
        this.phonenumber = phonenumber || '';
        this.countrycode = countrycode || Config.COUNTRY_CODE;
        this.status = status || Status.ACTIVE;
        this.activationcode = activationcode || '';
        this.idtypephone = idtypephone || '';
        this.iduser = iduser || '';
    }

    static parseFromPhoneDto(obj?: PhoneDto): PhoneFormDto {
        if (!obj) return new PhoneFormDto();

        return new PhoneFormDto(
            obj.id,
            obj.phonenumber,
            obj.countrycode,
            obj.status,
            obj.activationcode,
            obj.idtypephone,
            obj.iduser
        );
    }
}
