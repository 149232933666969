import { Box, Button, Grid, Typography } from "@mui/material";
import { MyCheckboxField } from "components/form/MyCheckboxField";
import MyTextField from "components/form/mytextfield";

import { RegisterUserDto } from "dto/auth/registeruser.dto";

import { useForm } from "hooks/useForm";
import { useGoogleRecaptcha } from "hooks/useGoogleRecaptcha";
import { useResource } from "hooks/useResource";
import React, { useCallback, useEffect, useState } from "react";
import RequiredValidator from "validators/required.validator";
import { PasswordStrengthChecker } from "./PasswordStrengthChecker";

import { PhoneTypeSelect } from "components/elements/Select/PhoneTypeSelect";
import { CountryCodeSelect } from "components/elements/Select/CountryCodeSelect";
import MyDatePicker from "components/elements/MyDatePicker/MyDatePicker";
import { NewLanguageSelect } from "components/static/language/NewLanguageSelect";

type Props = {
  onSubmit: (obj: RegisterUserDto) => void;
};

const FormSignUp: React.FC<Props> = ({ onSubmit }) => {
  const { LL, _idlanguage } = useResource();
  const { executeRecaptcha } = useGoogleRecaptcha();
  const [term, setTerm] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [disabledCheck, setDisabledCheck] = useState<boolean>(false);
  const [obj, disabled, setObjField] = useForm<RegisterUserDto>(
    new RegisterUserDto(_idlanguage),
    RequiredValidator.getValidators(["email", "password"])
  );

  const checkIsFormDisabled = useCallback(() => {
    let disabled = false;
    if (obj.password !== confirmPassword) {
      disabled = true;
    }
    if (!term) {
      disabled = true;
    }
    setDisabledCheck(disabled);
  }, [confirmPassword, obj.password, term]);

  useEffect(() => {
    checkIsFormDisabled();
  }, [checkIsFormDisabled]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled || disabledCheck) return;
    await executeRecaptcha();
    onSubmit(obj);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={4}>
        <Typography variant="h6">{LL("Name")}</Typography>
        <MyTextField
          fullWidth
          id="name"
          name="name"
          className="form-input"
          variant="standard"
          _label={LL("Name")}
          value={obj.name}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box mb={4}>
        <Typography variant="h6">{LL("Surname")}</Typography>
        <MyTextField
          fullWidth
          id="surname"
          name="surname"
          className="form-input"
          variant="standard"
          _label={LL("Surname")}
          value={obj.surname}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>

      <Box mb={4}>
        <Typography variant="h6">{LL("Email")}</Typography>
        <MyTextField
          fullWidth
          id="email"
          name="email"
          className="form-input"
          variant="standard"
          _label={LL("Email")}
          value={obj.email}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box mb={1}>
        <Typography variant="h6">{LL("Password")}</Typography>
        <MyTextField
          fullWidth
          type="password"
          id="password"
          name="password"
          className="form-input"
          variant="standard"
          _label={LL("Password")}
          value={obj.password}
          setObj={setObjField}
          _vresults={undefined}
        />
      </Box>
      <Box mb={3}>
        <PasswordStrengthChecker password={obj.password} />
      </Box>
      <Box mb={3}>
        <Typography variant="h6">{LL("Confirm Password")}</Typography>
        <MyTextField
          fullWidth
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          className="form-input"
          variant="standard"
          _label={LL("Confirm Password")}
          value={confirmPassword}
          setObj={(field: any, value: any) => setConfirmPassword(value)}
          _vresults={undefined}
        />
      </Box>
      <Box mb={4}>
        <Typography variant="h6">{LL("birthday")}</Typography>
        <MyDatePicker
          tabIndex={3}
          field="birthday"
          _defaultValue={obj.birthday}
          className="form-picker"
          LL={LL}
          setObjField={setObjField}
        />
      </Box>
      <Box>
        <Typography variant="h6">{LL("phone number")}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <CountryCodeSelect
              value={obj.countrycode}
              field="countrycode"
              size="small"
              variant="standard"
              color="primary"
              className="form-select"
              setObj={setObjField}
              obj={obj}
            />
          </Grid>
          <Grid item xs={8}>
            <MyTextField
              tabIndex={1}
              fullWidth
              id="phonenumber"
              name="phonenumber"
              className="form-input"
              variant="standard"
              _label={LL("phonenumber")}
              value={obj.phonenumber}
              setObj={setObjField}
              _vresults={undefined}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">{LL("Type phone")}</Typography>
        <PhoneTypeSelect
          value={obj.idtypephone}
          field="idtypephone"
          setObjectField={setObjField}
          label=""
        />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6">{LL("language")}</Typography>
        <NewLanguageSelect
          value={obj.idlanguage}
          setObjectField={setObjField}
          label=""
        />
      </Box>
      <Box mt={2} className="checkTerm">
        <MyCheckboxField
          id="notificationtype1"
          name="notificationtype1"
          label={LL("notificationtype1")}
          field="notificationtype1"
          setObjectField={setObjField}
          checked={obj.notificationtype1}
          color="primary"
          size="small"
        />
      </Box>
      <Box mt={2} className="checkTerm">
        <MyCheckboxField
          id="term"
          name="term"
          label={LL("term")}
          field="term"
          setObjectField={(field: string, value: any) => {
            setTerm(value);
          }}
          checked={term}
          color="primary"
          size="small"
        />
      </Box>

      <Box mt={2}>
        <Button
          disabled={disabled || disabledCheck}
          color="primary"
          fullWidth
          variant="contained"
          type="submit"
          className="btn">
          {LL("Bt SignUp")}
        </Button>
      </Box>
    </form>
  );
};

export { FormSignUp };
