import React, { useState } from "react";
import { useResource } from "hooks/useResource";

import { CourseService } from "services/course/course.service";

import { CourseDto } from "dto/course/course.dto";

import RequestListDTO from "dto/app/requestlist.dto";
import { Status } from "tools//types/status";
import RequestFilterDTO from "dto/app/requestfilter.dto";

import { CourseCard } from "components/course/course/CourseCard";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";

import { LoadMoreButton } from "components/elements/MyButton/LoadMoreButton";

import { SkeletonCourseBlock } from "components/elements/Skeleton/Skeleton";
import { Box, Grid } from "@mui/material";
import { CallbackType } from "interfaces/commontypes.interface";
import { useList } from "hooks/useList";

const service = new CourseService();

type Props = {
  idTeacher: string;
};

const CourseListByTeacherDisplayBlock: React.FC<Props> = ({ idTeacher }) => {
  const { CC } = useResource();
  // const [isLoading, setIsLoading] = useState(true);
  // const [objects, setObjects] = useState<Array<CourseDto>>([]);
  // const [total, setTotal] = useState<number>(-1);
  // const [totalPage, setTotalPage] = useState<number>(-1);
  const [onPage, setOnPage] = useState<number>(
    parseInt(CC("DefaultOnPageCourseList", "4"))
  );

  const getList = (cb?: CallbackType, cbParams?: any, req?: RequestListDTO) => {
    if (!idTeacher) return;
    service.getList(cb, cbParams, req);
  };

  const [loading, objects, total] = useList<CourseDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("idteacher", [idTeacher]),
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
      ],
      1,
      onPage,
      [RequestSortCriteriaDTO.prepareSortCriteria("popularidentifier", false)]
    ),
    [idTeacher, onPage]
  );
  // const calculateOnPage = () => {
  //     if(!objects) return;
  //     if(!objects.length) return;
  //     if(total === -1) return;

  //     if(total > onPage) {
  //         let localOnPage = onPage;
  //         localOnPage += parseInt(CC('DefaultOnPageCourseList',4))
  //         setOnPage(localOnPage);
  //     }
  // }

  // const processLoadMoreObjects = () => {
  //     if(!objects || !objects.length) return null
  //     if(total === -1) return null
  //     if(total <= onPage) return null
  //     return (
  //         <Box>
  //             <MyButton cb={calculateOnPage}>
  //                 {LL('Bt_LoadMore')}
  //             </MyButton>
  //         </Box>
  //     )
  // }

  const processLoadMoreObjects = () => {
    if (!objects || !objects.length) return null;
    return (
      <LoadMoreButton
        objects={objects}
        total={total}
        onPage={onPage}
        setOnPage={setOnPage}
        className="loadMorebtn"
        sx={{ my: 2 }}
        step={parseInt(CC("DefaultOnPageCourseList", "4"))}
      />
    );
  };

  // const getList = () => {
  //   const reqList = new RequestListDTO();
  //   reqList.onpage = onPage;
  //   reqList.page = 1;
  //   reqList.filters = [];
  //   reqList.sortcriteria = [];
  //   let sortCriteria = new RequestSortCriteriaDTO();
  //   sortCriteria.field = "popularidentifier";
  //   sortCriteria.asc = false;
  //   reqList.sortcriteria.push(sortCriteria);

  //   let f: RequestFilterDTO;

  //   f = new RequestFilterDTO();
  //   f.field = "status";
  //   f.values = [];
  //   f.values.push(Status.ACTIVE.toString());
  //   reqList.filters.push(f);
  //   f = new RequestFilterDTO();
  //   f.field = "idteacher";
  //   f.values = [idTeacher];
  //   reqList.filters.push(f);

  //   setIsLoading(true);

  //   service.getListRecommendedHome(loadObjects, {}, reqList);

  //   // setIsLoading(false);
  // };

  // const loadObjects = (result: ResultListDTO) => {
  //   if (!result) return;
  //   if (!result.err) {
  //     const objects = result.objects ? result.objects : [];
  //     const total = result.total !== undefined ? result.total : -1;
  //     const totalPage =
  //       result.totalpages !== undefined ? result.totalpages : -1;
  //     setObjects(objects);
  //     setTotal(total);
  //     setTotalPage(totalPage);
  //   }

  //   setIsLoading(true);
  // };

  // const checkLoading = () => {
  //   let load = false;

  //   if (!objects) load = true;
  //   // if (objects.length === 0) load = true;

  //   setIsLoading(load);
  // };
  // useEffect(() => {
  //   if (!idTeacher) return;
  //   getList();
  // }, [idTeacher, onPage]);

  // useEffect(() => {
  //   checkLoading();
  // }, [objects]);

  const processCourseItem = (obj: CourseDto, index: number) => {
    if (!obj) return null;
    return (
      <Grid item key={index} sm={12} md={4} lg={3}>
        <CourseCard obj={obj} loading={loading} />
      </Grid>
    );
  };

  const processCourseList = () => {
    if (!objects) return null;
    if (!objects.length) return null;

    return (
      <React.Fragment>
        {objects.map((item: CourseDto, i: number) => {
          return processCourseItem(item, i);
        })}
      </React.Fragment>
    );
  };

  return loading ? (
    <SkeletonCourseBlock />
  ) : (
    <Box>
      <Grid container direction="row" spacing={1}>
        {processCourseList()}
      </Grid>
      {processLoadMoreObjects()}
    </Box>
  );
};

export { CourseListByTeacherDisplayBlock };
