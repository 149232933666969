import React, { useEffect, useState } from "react";
import PageComponentProps from "interfaces/pagecomponentprops.interface";
import Box from "@mui/material/Box";

import { TeacherService } from "services/user/teacher.service";
import { TeacherDto } from "dto/user/teacher.dto";
import { useAuth } from "hooks/useAuth";

import { CircularLoading } from "components/elements/loading/CircularLoading";

import AddTeacherMediaComponent from "components/user/teacher/AddTeacherMediaComponent";

const teacherService = new TeacherService();

const EditTeacherMedia: React.FC<PageComponentProps> = ({
  currentRoute,
  ...props
}) => {
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(true);
  const [obj, setObj] = useState<TeacherDto | undefined>();
  const _id = user !== undefined ? user.id : "";
  const [id, setId] = useState<string>(_id);
  const [objectWasChanged, setObjectWasChanged] = useState<string>("");

  const loadObject = (obj: any) => {
    if (!obj) return;
    if (!obj.err) {
      const object = obj.obj ? obj.obj : [];
      setObj(object);
    }
  };

  const getObject = () => {
    setLoading(true);
    if (id !== undefined && id !== "")
      teacherService.getByUserId(id, loadObject, {});
  };

  const checkIsLoading = () => {
    let load: boolean = false;
    if (obj === undefined) load = true;
    setLoading(load);
  };

  useEffect(() => {
    getObject();
  }, [currentRoute, objectWasChanged]);

  useEffect(() => {
    checkIsLoading();
  }, [obj]);

  useEffect(() => {
    if (user !== undefined) {
      setId(user.id);
    }
  }, [user]);

  return user !== undefined && !loading && obj ? (
    <>
      <Box p={3}>
        <AddTeacherMediaComponent
          setLoading={setLoading}
          currentRoute={currentRoute}
          objTeacher={obj}
          loading={loading}
          setObjectWasChanged={setObjectWasChanged}
        />
      </Box>
    </>
  ) : (
    <CircularLoading />
  );
};

export { EditTeacherMedia };
